import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-down.svg';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

export type DropdownOption = {
  id: string;
  value: string;
  name?: String;
};

type Props = {
  placeholder?: string;
  defaultValue?: DropdownOption;
  options: DropdownOption[];
  className?: string;
  onSelectOption: (item: DropdownOption) => void;
};

const Dropdown = ({
  placeholder,
  defaultValue,
  options,
  className,
  onSelectOption,
}: Props) => {
  const [selected, setSelected] = useState<DropdownOption | undefined>(
    defaultValue,
  );
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => {
    setOpen(false);
  });
  const onToggleOptions = () => {
    setOpen(prev => !prev);
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue])

  const onSelectHandler = (selected: DropdownOption) => {
    setSelected(selected);
    onSelectOption(selected);
    onToggleOptions();
  };

  return (
    <div
      ref={dropdownRef}
      className={`relative w-full h-11 flex flex-col shadow-lg border border-gray-300 rounded-lg ${className}`}>
      <div
        className="w-full h-full flex items-center px-4 cursor-pointer"
        onClick={onToggleOptions}>
        <input
          className="w-full h-full outline-none text-start cursor-pointer caret-transparent select-none"
          contentEditable={false}
          readOnly
          value={selected?.value || ''}
          placeholder={placeholder}
        />
        <ArrowIcon />
      </div>
      {open && (
        <div className="w-full absolute top-11 bg-white shadow-lg rounded-md z-50">
          <ul className="max-h-60 overflow-auto">
            {options.map(option => (
              <li
                key={option.id}
                onClick={() => onSelectHandler(option)}
                className="py-3 cursor-pointer px-4 hover:bg-slate-100">
                {option.name ? option.name : option.value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
