type Props = {
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  className?: string;
  disabled?: boolean;
};

const Checkbox = ({ onClick, isChecked, className, disabled }: Props) => {
  return (
    <input
      onChange={onClick}
      className={`w-5 h-5 text-primary-main focus:ring-0 rounded cursor-pointer ${
        className || ''
      } ${disabled && ' text-gray-400 cursor-default'}`}
      type="checkbox"
      checked={isChecked}
      disabled={disabled}
    />
  );
};

export default Checkbox;
