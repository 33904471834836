import { ReactComponent as ExternalLinkIcon } from '../../assets/external-link.svg';

const Payments = () => {
  return (
    <div className="flex flex-col w-full h-full overflow-auto mx-6 scrollbar-hide">
      <h1 className="text-secondary-main text-[50px] my-6 mr-24">Manage Payments</h1>

      <h3 className="text-xl font-bold text-secondary-main  mt-9">
        Open Stripe Dashboard
      </h3>
      <a
        rel="noreferrer"
        className="flex items-center text-primary-main text-lg mt-1"
        href="https://connect.stripe.com/login"
        target="_blank">
        Stripe
        <ExternalLinkIcon className="w-5 h-5 ml-1 fill-primary-main" />
      </a>
    </div>
  );
};

export default Payments;
