import useApi from '../../hooks/useApi';
import { ForgotPasswordResponse } from '../../models/forgot-password';

const useChangePassword = () => {
  const { launchCall, status, response, isLoading, error } =
    useApi<ForgotPasswordResponse>('users/v2/reset-password-token');

  const changePassword = (
    newPassword: string,
    email: string,
    token: string,
  ) => {
    launchCall({
      method: 'POST',
      body: JSON.stringify({
        email,
        newPassword,
        token,
      }),
    });
  };

  return { response, changePassword, isLoading, error, status};
};

export default useChangePassword;
