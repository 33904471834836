import Checkbox from '../Checkbox';
import React from 'react';

export type TableColumn<T> = {
  id: keyof T;
  label: string;
};

type Props<T extends { id: string }> = {
  className?: string;
  data: T[];
  columns: TableColumn<T>[];
  selectable?: boolean;
  selectedRows?: T[];
  currentPage?: number;
  getActions?: (activeRow: T) => React.ReactNode;
  onRowSelection?: (selected: boolean, row: T) => void;
};

const Table = <T extends { id: string }>({
  className,
  data,
  columns,
  selectable = false,
  selectedRows,
  getActions,
  onRowSelection,
  currentPage,
}: Props<T>) => {
  const handleRowSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: T,
  ) => {
    const target = e.target as HTMLInputElement;
    onRowSelection?.(target.checked, row);
  };

  console.log({ columns, data });
  return (
    <div className="w-full min-h-[252px] shadow-md border border-gray-50 rounded-md overflow-x-scroll">
      <table className={`w-full ${className}`}>
        <thead className="sticky top-0 bg-white shadow-sm z-10">
          <tr>
            <>
              {selectable && <th className="p-2" />}
              {columns.map(({ id, label }) => (
                <th
                  key={id as string}
                  className="p-2 text-center text-secondary-main">
                  {label}
                </th>
              ))}
            </>
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <tr
              key={row.id}
              className="border-b border-b-gray-200 border-collapse last:border-none">
              {selectable && (
                <td className="p-2 text-center first:text-start">
                  <Checkbox
                    isChecked={!!selectedRows?.find(({ id }) => id === row.id)}
                    onClick={e => handleRowSelection(e, row)}
                  />
                </td>
              )}
              {columns.map(({ id }) => (
                <td
                  key={id as string}
                  className="p-4 text-center overflow-ellipsis max-w-[190px] overflow-hidden whitespace-nowrap">
                  {id !== 'actions' ? (
                    id === 'serial' ? (
                      <>
                        {currentPage
                          ? currentPage * 20 - 20 + Number(row[id])
                          : row[id] ?? '-'}
                      </>
                    ) : (
                      <>{row[id] ?? '-'}</>
                    )
                  ) : (
                    <div className="flex items-center justify-center">
                      {getActions?.(row)}
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className="w-full h-[350px] flex justify-center items-center">
          <h1 className="text-xl text-secondary-main">No data found</h1>
        </div>
      )}
    </div>
  );
};

export default Table;
