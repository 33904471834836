import { useEffect, useState, useRef } from 'react';
import useApi from '../../../hooks/useApi';

const useReferredUsers = () => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall, error } = useApi<any>(
    'admin-panel/get-references-of-users',
  );

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    try {
      launchCall({ method: 'GET' }, { ...applyFiltersRef.current });
    } catch (err) {
      console.log(err);
    }
  };


  return {
    getUsers,
    users: response?.data,
    isLoading,
    error: errorMessage,
  };
};

export default useReferredUsers;
