import { useMemo, useState } from 'react';

interface UserProfilePhotoProps {
  profileUrl: string | undefined;
  firstName: string;
}

const UserProfilePhoto = ({ profileUrl, firstName }: UserProfilePhotoProps) => {
  const [hasPhoto, setHasPhoto] = useState<boolean>(!!profileUrl);

  const imagesHostUrl = process.env.REACT_APP_IMAGES_HOST_URL;
  const nameFirstLetter = useMemo(() => {
    return firstName?.at(0) || 'U';
  }, [firstName]);

  // Show Avatar if image load fails
  const onImageError = () => {
    setHasPhoto(false);
  };

  return (
    <div>
      {hasPhoto ? (
        <img
          className="w-[120px] h-auto rounded-full"
          src={`${imagesHostUrl}${profileUrl}`}
          alt=""
          onError={onImageError}
        />
      ) : (
        <p
          className={[
            'w-[80px] h-[80px] rounded-full',
            'flex justify-center items-center',
            'font-bold text-white text-4xl uppercase',
            'bg-secondary-main',
          ].join(' ')}>
          {nameFirstLetter}
        </p>
      )}
    </div>
  );
};

export default UserProfilePhoto;
