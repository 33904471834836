import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs';
import ProviderDetail from '../../components/ProviderDetail';
import { Provider } from '../../models/provider';

const ProviderDetailsPage = () => {
  const { state } = useLocation();
  const providerData: Provider = useMemo(() => {
    return state;
  }, [state]);


  if (!providerData) {
    return <Navigate to={'/providers'} />;
  }

  const breadCrumbs = [
    {
      link: '/providers',
      displayName: 'Providers',
    },
    {
      displayName: 'Provider Details',
    },
  ];

  return (
    <div>
      <div className="bg-[#f7faff] py-6 px-4 min-h-screen space-y-4">
        <BreadCrumbs breadcrumbs={breadCrumbs} />
        <ProviderDetail providerData={providerData} />
      </div>
    </div>
  );
};

export default ProviderDetailsPage;
