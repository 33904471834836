import { useEffect, useState } from 'react';
import { useSporforyaContext } from '../../context';
import useApi from '../../hooks/useApi';
import useCache from '../../hooks/useCache';
import { LoginResponse } from '../../models/login';
import {
  AUTHENTICATION_TOKEN_KEY,
  AUTHENTICATED_USER_INFO,
} from '../../utils/auth';

const useLogin = () => {
  const { setCacheData } = useCache();
  const { setToken, setUserInfo } = useSporforyaContext();
  const [rememberMe, setRememberMe] = useState(false);
  const { launchCall, response, isLoading, error, status } =
    useApi<LoginResponse>('admin/login');

  useEffect(() => {
    if (response) {
      rememberMe &&
        setCacheData(AUTHENTICATION_TOKEN_KEY, response?.data.access_token);
      setCacheData(AUTHENTICATED_USER_INFO, response?.data.info);
      setToken(response?.data.access_token);
      setUserInfo(response?.data.info);
    }
  }, [response]);

  const login = (email: string, password: string) => {
    launchCall({
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    });
  };

  return {
    response: response?.data,
    login,
    isLoading,
    error,
    status,
    rememberMe,
    setRememberMe,
  };
};

export default useLogin;
