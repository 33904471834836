import React from 'react';
import { CSVLink } from 'react-csv';
import Dropdown, { DropdownOption } from '../../Dropdown';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Input from '../Input';

export type TableColumn<T> = {
  id: keyof T;
  label: string;
};

export type FilterColumnd<T> = {
  id: number;
  label: string;
  defaultValue: DropdownOption | undefined;
  options: { id: string; value: string }[];
  action: (value: DropdownOption) => void;
};

export type SearchProp<T> = {
  id: number;
  placeholder: string;
  value?: string | undefined;
  onInput: (value: string) => void;
};

export type CsvProp<T> = {
  fileName: string;
  data: any;
};

type Props<T extends { id: string }> = {
  className?: string;
  data?: T[];
  columns?: TableColumn<T>[];
  filters?: FilterColumnd<T>[];
  search?: SearchProp<T>[];
  selectable?: boolean;
  searchable?: boolean;
  filterable?: boolean;
  downloadCSV?: CsvProp<T>;
  selectedRows?: T[];
  currentPage?: number;
  getActions?: (activeRow: T) => React.ReactNode;
  reset?: () => void | undefined;
  onRowSelection?: (selected: boolean, row: T) => void;
};

const NewTable = <T extends { id: string }>({
  className,
  data,
  columns,
  filters,
  search,
  selectable = false,
  searchable = false,
  filterable = false,
  downloadCSV,
  selectedRows,
  reset,
  getActions,
  onRowSelection,
  currentPage,
}: Props<T>) => {
  const handleRowSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: T,
  ) => {
    const target = e.target as HTMLInputElement;
    onRowSelection?.(target.checked, row);
  };
  return (
    <div className="shadow-md sm:rounded-lg">
      {(filterable || searchable) && (
        <div className="flex flex-wrap gap-4 items-center my-10">
          {searchable &&
            search?.map(search => (
              <Input
                key={search?.id}
                className="w-full min-[480px]:w-[300px]"
                placeholder={search?.placeholder}
                value={search.value}
                onInput={search?.onInput}
              />
            ))}
          {filterable &&
            filters?.map((filter, i) => (
              <Dropdown
                key={filter?.id}
                defaultValue={filter?.defaultValue}
                placeholder={filter?.label}
                className="w-full min-[480px]:w-[200px]"
                options={filter?.options}
                onSelectOption={filter?.action}
              />
            ))}

          <Button
            className="w-full min-[480px]:w-[200px]"
            text="Reset"
            onClick={reset}
          />
        </div>
      )}
      <div className="flex items-center justify-end pb-2">
        {downloadCSV && (
          <CSVLink
            className="w-full min-[480px]:w-[200px] text-center font-semibold text-secondary-main justify-center"
            data={downloadCSV?.data}
            filename={downloadCSV?.fileName}>
            Download CSV
          </CSVLink>
        )}
      </div>

      <table className="w-full overflow-x-auto  text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
          <tr>
            {selectable && <th className="p-2" />}
            {columns?.map(({ id, label }) => (
              <th
                key={id as string}
                className="p-2 text-center text-secondary-main">
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map(row => (
            <tr
              key={row.id}
              className="border-b border-b-gray-200 border-collapse last:border-none">
              {selectable && (
                <td className="p-2 text-center first:text-start">
                  <Checkbox
                    isChecked={!!selectedRows?.find(({ id }) => id === row.id)}
                    onClick={e => handleRowSelection(e, row)}
                  />
                </td>
              )}
              {columns?.map(({ id }) => (
                <td
                  key={id as string}
                  className="p-4 text-center overflow-ellipsis max-w-[150px] overflow-hidden whitespace-nowrap">
                  {id !== 'actions' ? (
                    id === 'serial' ? (
                      <>
                        {currentPage
                          ? currentPage * 20 - 20 + Number(row[id])
                          : row[id] ?? '-'}
                      </>
                    ) : (
                      <>{row[id] ?? '-'}</>
                    )
                  ) : (
                    <div className="flex items-center justify-center">
                      {getActions?.(row)}
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewTable;
