/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import useCreateAdmin from './useCreateAdmin';
import { toast } from 'react-toastify';

type Props = {
  userRole?: string;
};

const CreateAdmin = ({ userRole = 'New Admin' }: Props) => {
  const navigate = useNavigate();
  const firstNameRef = useRef('');
  const lastNameRef = useRef('');
  const emailRef = useRef('');
  const passwordRef = useRef('');
  const confirmPasswordRef = useRef('');
  const { createAdmin, response, isLoading, error, status } = useCreateAdmin();
  const [passwordNotMatchedRef, setPasswordNotMatchedRef] = useState(false);

  useEffect(() => {
    if (response) {
      toast.success(response?.message);
      navigate('/admins');
    }
  }, [response]);

  useEffect(() => {
    console.log({status})
  }, [error])

  const onSubmitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (passwordRef?.current !== confirmPasswordRef?.current) {
      setPasswordNotMatchedRef(true);
    } else {
      createAdmin(
        firstNameRef.current,
        lastNameRef.current,
        passwordRef.current,
        emailRef?.current,
      );
      (response?.status === 200 || response?.status === 201) &&
        toast.success(response?.message);
    }
  };

  const onFirstNameChange = (value: string) => {
    firstNameRef.current = value;
  };

  const onLastNameChange = (value: string) => {
    lastNameRef.current = value;
  };

  const onEmailChange = (value: string) => {
    emailRef.current = value;
  };

  const onPasswordChange = (value: string) => {
    passwordRef.current = value;
    if (passwordRef?.current === confirmPasswordRef?.current) {
      setPasswordNotMatchedRef(false);
    }
  };
  const onConfirmPasswordChange = (value: string) => {
    confirmPasswordRef.current = value;
    if (passwordRef?.current === confirmPasswordRef?.current) {
      setPasswordNotMatchedRef(false);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide mb-4">
        <h1 className="text-secondary-main text-[50px] my-6 first-letter:uppercase">
          {userRole}
        </h1>
        <div>
          <form
            className="w-full md:w-[460px] space-y-4 mx-auto flex flex-col justify-between"
            onSubmit={onSubmitHandle}>
            <Input
              required
              className="max-[920px]:border-transparent"
              type="text"
              placeholder="First Name"
              onInput={onFirstNameChange}
            />
            <Input
              required
              className="max-[920px]:border-transparent"
              type="text"
              placeholder="last Name"
              onInput={onLastNameChange}
            />
            <Input
              required
              className="max-[920px]:border-transparent"
              type="email"
              placeholder="Email"
              onInput={onEmailChange}
            />

            <Input
              required
              className="max-[920px]:border-transparent"
              type="password"
              placeholder="Password"
              onInput={onPasswordChange}
            />
            <Input
              required
              className="max-[920px]:border-transparent"
              type="password"
              placeholder="Confirm Password"
              onInput={onConfirmPasswordChange}
            />
            <Button type="submit" text="Submit" showLoader={isLoading} />
            <span
              className={`text-xs text-center text-red-500 font-bold ${
                !error && 'opacity-0'
              }`}>
            {status === 400 ? 'This email already exist in our system.' : 'Only owner can perform this action.'}
            </span>
            <span
              className={`text-xs text-center text-red-500 font-bold ${
                !passwordNotMatchedRef && 'opacity-0'
              }`}>
              Password does not match !
            </span>
            <span
              className={`text-sm text-center text-primary-main font-bold ${
                !response && 'opacity-0'
              }`}>
              {response?.message}
            </span>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateAdmin;
