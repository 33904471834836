import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { User } from '../../models/user';
import UserDetail from '../../components/UserDetail';
import BreadCrumbs from '../../components/BreadCrumbs';

const UserDetailsPage = () => {
  const { state } = useLocation();
  const userData: User = useMemo(() => {
    return state;
  }, [state]);

  if (!userData) {
    return <Navigate to={'/users'} />;
  }

  const breadCrumbs = [
    {
      link: '/users',
      displayName: 'Users',
    },
    {
      displayName: 'User Details',
    },
  ];

  return (
    <div>
      <div className="bg-[#f7faff] py-6 px-4 min-h-screen">
        <BreadCrumbs breadcrumbs={breadCrumbs} />
        <UserDetail userData={userData} />
      </div>
    </div>
  );
};

export default UserDetailsPage;
