type Props = {
  className?: string;
  children: React.ReactNode;
  onClose: () => void;
};

const Dialog = ({ children, onClose, className }: Props) => {
  return (
    <>
      <div
        className="w-full h-[1800px] absolute top-0 left-0 bg-secondary-main opacity-70 z-50"
        onClick={onClose}
      />
      <div
        className={`flex flex-col items-center fixed w-fit h-fit p-6 bg-white rounded-xl z-[60] top-0 left-0 right-0 bottom-0 m-auto ${className}`}>
        {children}
      </div>
    </>
  );
};

export default Dialog;
