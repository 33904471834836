import { FC, useState } from 'react';
import Dialog from '../Dialog';
import Button from '../ui/Button';

interface Props {
  onClose: () => void;
  onSubmit: (message: string) => void;
  title?: string;
  placeholder: string;
  buttonText?: string;
  description?: string;
}

export const NotificationDialog: FC<Props> = ({
  onClose,
  onSubmit,
  placeholder,
  title = 'Send a Message',
  buttonText = 'Send',
  description = 'The user will receive a notifications in the app OR on their email account.',
}) => {
  const [message, setMessage] = useState('');

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(message);
  };

  return (
    <Dialog
      className="w-[90%] md:w-[600px] h-[420px] justify-around"
      onClose={onClose}>
      <div>
        <h1 className="text-2xl text-center mb-3">{title}</h1>
        <p>{description}</p>
      </div>
      <form className="w-full" onSubmit={handleSubmit}>
        <textarea
          required
          value={message}
          onChange={handleMessageChange}
          placeholder={placeholder}
          className="w-full focus:outline-none mb-4 focus:shadow-none focus:border-none focus:ring-0 text-secondary-main border-none max-h-40 md:max-h-[200px] h-40 bg-[#f6f8f96e] placeholder:text-[#b7b7b7]"
        />
        <Button text={buttonText} type="submit" />
      </form>
    </Dialog>
  );
};
