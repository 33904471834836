import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SporforyaContext, initContext } from './context';
import useCache from './hooks/useCache';
import { User } from './models/user';
import AdminPanelPage from './pages/AdminPanel';
import Bookings from './pages/Bookings';
import Dashboard from './pages/Dashboard';
import Listing from './pages/Listing';
import ListingDetailsPage from './pages/ListingDetailsPage';
import BookingDetailsPage from './pages/BookingDetails';
import LoginPage from './pages/Login';
import Payments from './pages/Payments';
import Providers from './pages/Providers';
import Reviews from './pages/Reviews';
import Users from './pages/Users';
import UserDetailsPage from './pages/UserDetailsPage';
import Protected from './routes/Protected';
import {
  AUTHENTICATION_TOKEN_KEY,
  AUTHENTICATED_USER_INFO,
} from './utils/auth';
import Reports from './pages/Reports';
import { ToastContainer } from 'react-toastify';
import SubscribedUsers from './pages/SubscribedUsers';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Admins from './pages/Admin';
import CreateAdmin from './pages/Admin/CreateAdmin';
import OwnerProtected from './routes/OwnerProtected';
import SettingsPage from './pages/SettingsPage';
import ProviderDetailsPage from './pages/ProviderDetailsPage';
import ReferredUsers from './pages/ReferredUsers';

const App = () => {
  const { getCacheData } = useCache();
  const [userInfo, setUserInfo] = useState<User | undefined>(
    getCacheData<User>(AUTHENTICATED_USER_INFO),
  );
  const [token, setToken] = useState<string | undefined>(
    getCacheData<string>(AUTHENTICATION_TOKEN_KEY),
  );

  const initialValues = {
    ...initContext,
    token,
    setToken,
    userInfo,
    setUserInfo,
  };

  return (
    <SporforyaContext.Provider value={initialValues}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route
            path="/"
            element={
              <Protected>
                <AdminPanelPage />
              </Protected>
            }>
            <Route path="/" element={<Dashboard />} />
            {userInfo?.role === 'super-admin' && (
              <Route path="/admin" element={<Admins />} />
            )}

            <Route
              path="/"
              element={
                <OwnerProtected>
                  <CreateAdmin />
                </OwnerProtected>
              }>
              <Route path="/new-admin" element={<CreateAdmin />} />
            </Route>
            <Route path="/listings" element={<Listing />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path={'/listings/:id'} element={<ListingDetailsPage />} />
            {/* <Route path={'/listings/edit/:id'} element={<ListingEditPage />} /> */}
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<UserDetailsPage />} />

            <Route path="/subscribed-users" element={<SubscribedUsers />} />
            <Route path="/referrals" element={<ReferredUsers />} />
            {/* <Route path="/users/edit/:id" element={<UserEdit />} /> */}
            <Route path="/providers" element={<Providers />} />
            <Route path="/providers/:id" element={<ProviderDetailsPage />} />
            {/* <Route path="/providers/edit/:id" element={<UserEdit />} /> */}
            <Route path="/payments" element={<Payments />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/bookings/:id" element={<BookingDetailsPage />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/reports" element={<Reports />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={2000} position="bottom-right" />
    </SporforyaContext.Provider>
  );
};

export default App;
