/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import useResetPassword from './useResetPassword';
import { toast } from 'react-toastify';

const SettingsPage = () => {
  const navigate = useNavigate();
  const newPasswordRef = useRef('');
  const currentPasswordRef = useRef('');
  const { changePassword, response, isLoading, error } =
    useResetPassword();

  useEffect(() => {
    if (response) {
      toast.success(response?.message);
      navigate('/login');
    }
  }, [response]);


  const onSubmitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    changePassword(currentPasswordRef.current, newPasswordRef.current);
    (response?.status === 200 || response?.status === 201) &&
      toast.success(response?.message);
  };

  const onPasswordChange = (value: string) => {
    newPasswordRef.current = value;
  };
  const onCurrentPasswordChange = (value: string) => {
    currentPasswordRef.current = value;
  };

  return (
    <div className="mt-10 ml-10">
      <h1 className="text-5xl text-white min-[920px]:text-black">Settings</h1>
      <div className="mt-10">
        <h2 className="text-2xl text-white min-[920px]:text-black">
          Change Password
        </h2>
        <hr />
        <div className="w-full mt-4">
          <form
            className="w-[360px] space-y-4 flex flex-col justify-between border-2 rounded-md px-4 pt-4"
            onSubmit={onSubmitHandle}>
            <Input
              required
              className="max-[920px]:border-transparent"
              type="password"
              placeholder="Current Password"
              onInput={onCurrentPasswordChange}
            />
            <Input
              required
              className="max-[920px]:border-transparent"
              type="password"
              placeholder="New Password"
              onInput={onPasswordChange}
            />
            <Button type="submit" text="Submit" showLoader={isLoading} />
            <span
              className={`text-xs text-center text-red-500 font-bold py-2 ${
                !error && 'opacity-0 py-0'
              }`}>
              The password change is not possible. please try again !
            </span>
            <span
              className={`text-sm text-center text-primary-main font-bold py-2 ${
                !response && 'opacity-0 py-0'
              }`}>
              {response?.message}
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
