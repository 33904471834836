import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import { Provider, ProviderTable } from '../models/provider';

const ProvidersColumns: TableColumn<ProviderTable>[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'status', label: 'Status' },
  { id: 'verification', label: 'Verification' },
  { id: 'stripe', label: 'Stripe' },
  { id: 'bookings', label: 'Bookings' },
  { id: 'listings', label: 'Listing' },
  { id: 'moneyEarned', label: 'Money Earn' },
  { id: 'createdAt', label: 'Date' },
];

const getTableHeader = (readOnly: boolean) => {
  const columns = ProvidersColumns;
  return readOnly
    ? columns
    : ([
        ...columns,
        { id: 'actions', label: 'Actions' },
      ] as TableColumn<ProviderTable>[]);
};

export const ProvidersToProviderDataTable = (
  providers: Provider[],
  readOnly = false,
  providerRole: string,
) => {
  const data: ProviderTable[] = providers.map(
    (
      {
        id,
        isVerified,
        firstName,
        lastName,
        email,
        user,
        sportypoint,
        totalBooking,
        bookingCountProvider,
        hasActiveBooking,
        hasUpcomingBooking,
        isOnboardingCompleted,
        isBanned,
        isOnline,
        isOrganization,
        organizationInfo,
        organizationName,
        providerInfo,
        listing,
        moneyEarned,
        moneySpent,
        verifyShortNumberToken,
        updatePhone,
        twoFa,
        createdAt,
        updatedAt,
      },
      index,
    ) => {
      const values: ProviderTable = {
        id: id,
        serial: (index + 1).toString(),
        bookings: totalBooking,
        providerBookigns: bookingCountProvider,
        hasActiveBooking: hasActiveBooking,
        hasUpcomingBooking: hasUpcomingBooking,
        listings: listing?.length,
        moneyEarned,
        isBanned: isBanned,
        isOnline: isOnline,
        isOrganization: isOrganization,
        organizationInfo: organizationInfo,
        providerInfo: providerInfo,
        status: user?.isBanned
          ? 'Banned'
          : isOnboardingCompleted
          ? 'Completed'
          : 'Incompleted',
        verification:
          user?.userIdVerification != null
            ? user?.userIdVerification?.status
            : 'Not-Verified',
        stripe:
          user?.userStripeConnectAccount != null
            ? 'connected'
            : 'Not-Connected',
        name: isOrganization ? organizationName : user?.fullName,
        firstName: firstName,
        lastName: lastName,
        email: email,
        isVerified: isVerified?.toString(),
        signUpOtp: verifyShortNumberToken ?? null,
        phoneUpdateOtp: updatePhone?.otp ?? null,
        twoFaOtp: twoFa?.otp ?? null,
        createdAt: format(
          createdAt ? new Date(createdAt) : new Date(),
          'MM-dd-yyy hh:mm a',
        ),
        updatedAt: format(
          updatedAt ? new Date(updatedAt) : new Date(),
          'MM-dd-yyy hh:mm a',
        ),
        sportyPoint: sportypoint?.toString(),
        moneySpent: moneySpent?.toString() || '0',
      };

      if (!readOnly) {
        values.actions = 'ACTIONS';
      }
      return values;
    },
  );
  return {
    headers: getTableHeader(readOnly),
    data,
  };
};
