import StatusHandler from '../../components/StatusHandler';
import useDashboard from './useDashboard/useDashboard';

import { useEffect, useState } from 'react';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import { BookingTable } from '../../models/bookings';
// import { ListingTable } from '../../models/listings';
import { UserTable } from '../../models/user';
import { BookingToBookingTable } from '../../utils/bookings';
import {
  BestListingToListingDataTable,
  ListingToListingDataTable,
} from '../../utils/listings';
import { UsersToUserDataTable } from '../../utils/users';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import NewTable, { TableColumn } from '../../components/ui/NewTable';
import Button from '../../components/ui/Button';
import ToggleUserWall from './toggleUserWall';
import { BestListingTable, ListingTable } from '../../models/listings';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

const labels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const barData = {
  labels,
  datasets: [
    {
      label: 'users',
      data: [12, 34, 43, 45, 45, 33, 44, 43, 45, 45, 33, 44],
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
    },
    {
      label: 'providers',
      data: [8, 24, 23, 25, 28, 23, 24, 8, 24, 23, 25, 28],
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
    },
  ],
};
const mockedMonths = [
  {
    id: '0',
    value: 'January 2024',
  },
  {
    id: '1',
    value: 'February 2024',
  },
  {
    id: '2',
    value: 'March 2024',
  },
  {
    id: '3',
    value: 'April 2024',
  },
  {
    id: '4',
    value: 'May 2024',
  },
  {
    id: '5',
    value: 'June 2024',
  },
  {
    id: '6',
    value: 'July 2024',
  },
  {
    id: '7',
    value: 'Auguts 2024',
  },
  {
    id: '8',
    value: 'September 2024',
  },
  {
    id: '9',
    value: 'October 2024',
  },
  {
    id: '10',
    value: 'November 2024',
  },
  {
    id: '11',
    value: 'December 2024',
  },
];

const initialTablesData = {
  headers: [],
  data: [],
};

const Dashboard = () => {
  const { metrics, isLoading, error, applyFilters } = useDashboard();
  const [listingsData, setListingsData] = useState<{
    headers: TableColumn<BestListingTable>[];
    data: BestListingTable[];
  }>({ headers: [], data: [] });
  useEffect(() => {
    if (metrics?.bestSellingListings) {
      setListingsData(
        BestListingToListingDataTable(metrics?.bestSellingListings),
      );
    }
  }, [metrics]);

  const [filter, setSelectedFilter] = useState<DropdownOption>(mockedMonths[0]);

  const handleMonthChange = (option: DropdownOption) => {
    applyFilters({ monthNumber: option.id });
    setSelectedFilter(option);
  };

  const [listings, setListings] = useState<any>({ all: 0, new: 0 });
  const [bookings, setBookings] = useState<any>({ all: 0, new: 0 });
  const [providers, setProviders] = useState<any>({ all: 0, new: 0 });

  useEffect(() => {
    setListings({
      all: metrics?.listingsCount,
      new: metrics?.newListing,
    });
  }, [metrics]);
  useEffect(() => {
    setBookings({
      all: metrics?.bookingsCount,
      new: metrics?.newBooking,
    });
  }, [metrics]);
  useEffect(() => {
    setProviders({
      all: metrics?.providersCount,
      new: metrics?.newProvider,
    });
  }, [metrics]);

  const listingDatas = {
    labels: ['New Listing', 'All Listing'],
    datasets: [
      {
        label: 'Listing',
        data: [listings?.new, listings?.all],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(75, 192, 192, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 1,
      },
    ],
  };
  const bookingDatas = {
    labels: ['New Booking', 'All Booking'],
    datasets: [
      {
        label: 'Booking',
        data: [bookings?.new, bookings?.all],
        backgroundColor: ['rgba(196, 108, 170, 0.2)', 'rgba(92, 73, 219, 0.2)'],
        borderColor: ['rgba(196, 108, 170, 1)', 'rgba(92, 73, 219, 1)'],
        borderWidth: 1,
      },
    ],
  };
  const providerDatas = {
    labels: ['New Providers', 'All Providers'],
    datasets: [
      {
        label: 'Provider',
        data: [providers?.new, providers?.all],
        backgroundColor: ['rgba(219, 112, 73, 0.2)', 'rgba(86, 232, 203, 0.2)'],
        borderColor: ['rgba(219, 112, 73, 1)', 'rgba(86, 232, 203, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const staticDatas: any = [
    { id: 1, name: 'Static', type: 'Pending' },
    { id: 2, name: 'Static', type: 'Pending' },
    { id: 3, name: 'Static', type: 'Pending' },
  ];

  const staticHeaders: any = [
    {
      label: 'ID',
      id: 'id',
    },
    {
      label: 'Name',
      id: 'name',
    },
    {
      label: 'Type',
      id: 'type',
    },

    {
      label: 'Status',
      id: 'status',
    },
  ];

  return (
    <div className="flex-1 w-full h-full overflow-auto px-6 py-6 scrollbar-hide">
      <div className="flex justify-between items-center mb-10">
        <div>
          <h1 className="text-4xl text-secondary-main mt-8">General Metrics</h1>
          <p>Dashboard</p>
        </div>
        <div className="flex gap-2">
          <ToggleUserWall
            metrics={metrics}
            updateMetrics={() => handleMonthChange(filter)}
          />
          <Dropdown
            placeholder="Type"
            className="w-full min-[480px]:w-[200px]"
            defaultValue={filter}
            options={mockedMonths}
            onSelectOption={handleMonthChange}
          />
        </div>
      </div>
      <StatusHandler isLoading={isLoading} error={error}>
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-2 flex flex-col items-center  border bg-white shadow-lg rounded-md py-4 px-2">
                <span className="text-4xl text-primary-main font-semibold">
                  {metrics?.totalRevenue}
                </span>
                <span className="text-lg text-secondary-main font-semibold">
                  Total Revenue
                </span>
              </div>
              <div className="flex flex-col items-center  border bg-white shadow-lg rounded-md py-4 px-2">
                <span className="text-4xl text-primary-main font-semibold">
                  {metrics?.usersCount}
                </span>
                <span className="text-lg text-secondary-main font-semibold">
                  Total Active Users
                </span>
              </div>
              <div className="flex flex-col items-center  border bg-white shadow-lg rounded-md py-4 px-2">
                <span className="text-4xl text-primary-main font-semibold">
                  {metrics?.providersCount}
                </span>
                <span className="text-lg text-secondary-main font-semibold">
                  Total Active Providers
                </span>
              </div>
              <div className="flex flex-col items-center  border bg-white shadow-lg rounded-md py-4 px-2">
                <span className="text-4xl text-primary-main font-semibold">
                  {metrics?.listingsCount}
                </span>
                <span className="text-lg text-secondary-main font-semibold">
                  Total Active Listings
                </span>
              </div>
              <div className="flex flex-col items-center  border bg-white shadow-lg rounded-md py-4 px-2">
                <span className="text-4xl text-primary-main font-semibold">
                  {metrics?.bookingsCount}
                </span>
                <span className="text-lg text-secondary-main font-semibold">
                  Total Active Bookings
                </span>
              </div>
            </div>
            <div className="border">
              <Bar options={options} data={barData} />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 my-12">
            <div className="border">
              <h2 className="text-lg font-bold text-center py-2">
                New Listing
              </h2>
              <div className="py-4">
                <Doughnut data={listingDatas} />
              </div>
            </div>
            <div className="border">
              <h2 className="text-lg font-bold text-center py-2">
                New Bookings
              </h2>
              <div className="py-4">
                <Doughnut data={bookingDatas} />
              </div>
            </div>
            <div className="border">
              <h2 className="text-lg font-bold text-center py-2">
                New Providers
              </h2>
              <div className="py-4">
                <Doughnut data={providerDatas} />
              </div>
            </div>
          </div>

          <h1 className="text-3xl text-secondary-main my-12">
            Best Sellings Listings
          </h1>
          <NewTable data={listingsData?.data} columns={listingsData?.headers} />
        </>
      </StatusHandler>
    </div>
  );
};

export default Dashboard;
