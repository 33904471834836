import { useState, HTMLInputTypeAttribute, useEffect } from 'react';
import { ReactComponent as ViewIcon } from '../../../assets/eye-fill.svg';
import { ReactComponent as ViewOffIcon } from '../../../assets/eye-off.svg';

type Props = {
  required?: boolean;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  onInput: (value: string) => void;
  onblur?: () => void;
};

const Input = ({
  placeholder,
  required,
  className,
  inputClassName,
  type,
  value,
  onInput,
  onblur,
}: Props) => {
  const [inputValue, setInputValue] = useState(value || '');

  const [showPassword, setShowPassword] = useState(false);

  const onInputHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setInputValue(value);
    onInput(value);
  };

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  return (
    <div
      className={`w-full flex flex-col justify-end text-lg border-b border-b-primary-main ${className}`}>
      <div className="flex gap2">
        <input
          required={required}
          value={inputValue}
          type={type === 'password' && showPassword ? 'text' : type}
          className={`flex-1 w-full outline-none p-2 rounded-[4px] border-none focus:ring-0 ${
            inputClassName || ''
          }`}
          placeholder={placeholder}
          onInput={onInputHandler}
          onBlur={onblur}
        />
        {(type === 'password') &&
          (showPassword ? (
            <ViewIcon className='cursor-pointer' onClick={() => setShowPassword(false)} />
          ) : (
            <ViewOffIcon className='cursor-pointer' onClick={() => setShowPassword(true)} />
          ))}
      </div>
    </div>
  );
};

export default Input;
