import {
  BookMarked,
  BriefcaseBusiness,
  Home,
  List,
  Settings,
  Star,
  UserPlus,
  Users,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header';
import Sidenav, { SideNavLink } from '../../components/Sidenav';
import { useSporforyaContext } from '../../context';
import useWindowSize from '../../hooks/useWindowSize';

const links: SideNavLink[] = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon: <Home />,
    path: '/',
  },
  {
    id: 'admins',
    label: 'Admins',
    icon: <Users />,
    path: '/admin',
  },
  {
    id: 'roles',
    label: 'Roles',
    icon: <BriefcaseBusiness />,
    path: '/roles',
  },
  {
    id: 'listings',
    label: 'Listings',
    icon: <List />,
    path: '/listings',
    heading: 'Menu',
  },
  {
    id: 'providers',
    label: 'Providers',
    icon: <Users />,
    path: '/providers',
  },
  {
    id: 'users',
    label: 'Users',
    icon: <UserPlus />,
    path: '/users',
  },
  {
    id: 'subscribedUsers',
    icon: <Users />,
    label: 'Subscribers',
    path: '/subscribed-users',
  },
  {
    id: 'referredUsers',
    icon: <Users />,
    label: 'Referrals',
    path: '/referrals',
  },
  {
    id: 'bookings',
    label: 'Bookings',
    icon: <BookMarked />,
    path: '/bookings',
  },
  // {
  //   id: 'payments',
  //   label: 'Payments',
  //   path: '/payments',
  // },
  {
    id: 'review&raiting',
    label: 'Review & Ratings',
    icon: <Star />,
    path: '/reviews',
  },
  // {
  //   id: 'reports',
  //   label: 'Reports',
  //   icon: <ClipboardPlus />,
  //   path: '/reports',
  // },
  // {
  //   id: 'financial',
  //   label: 'Financial',
  //   path: 'https://dashboard.stripe.com/login',
  //   external: true,
  // },
  // {
  //   id: 'support',
  //   label: 'Support',
  //   path: 'https://app.chatwoot.com/app/login?utm_source=navbar-link',
  //   external: true,
  // },
  // {
  //   id: 'messages',
  //   label: 'Messages',
  //   path: 'https://getstream.io/accounts/login/',
  //   external: true,
  // },
  // {
  //   id: 'aws',
  //   label: 'Email and SMS',
  //   path: 'https://console.aws.amazon.com/console/home?nc2=h_ct&src=header-signin"',
  //   external: true,
  // },
  {
    id: 'settings',
    label: 'Settings',
    icon: <Settings />,
    path: '/settings',
  },
];

const AdminPanelPage = () => {
  const { userInfo } = useSporforyaContext();
  const { width } = useWindowSize();
  const [open, setOpen] = useState(width >= 768);

  useEffect(() => {
    if (width >= 768) {
      setOpen(true);
    }
  }, [width]);
  return (
    <div className="flex w-full h-full">
      <Sidenav
        open={open}
        setOpen={setOpen}
        width={width}
        links={
          userInfo?.role === 'super-admin'
            ? links
            : links?.filter(
                link => link?.path !== '/admin' && link?.path !== '/roles',
              )
        }
      />
      <div className={`flex-1 ${open ? 'ml-[260px]' : 'ml-[70px]'}`}>
        <Header />
        <div className="overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminPanelPage;
