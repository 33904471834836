import { ReactComponent as ArrowIcon } from '../../assets/arrow-down.svg'; //TODO: let's call it arrow

type Props = {
  className?: string;
  nextPage: () => void;
  prevPage: () => void;
  currentPage?: number;
  totalPages?: number;
};

const Pagination = ({
  currentPage,
  totalPages,
  nextPage,
  prevPage,
  className,
}: Props) => {
  if (!currentPage || !totalPages) return null;

  return (
    <div className={`w-full flex justify-center items-center ${className}`}>
      <button
        className="p-1 mr-5 bg-secondary-main rounded-lg rotate-90"
        onClick={prevPage}>
        <ArrowIcon className="fill-white" />
      </button>

      <span className="text-lg text-secondary-main">
        {currentPage} of {totalPages}
      </span>

      <button
        className="p-1 ml-5 bg-secondary-main rounded-lg -rotate-90"
        onClick={nextPage}>
        <ArrowIcon className="fill-white" />
      </button>
    </div>
  );
};

export default Pagination;
