import { Bell, CircleUser } from 'lucide-react';
import { useSporforyaContext } from '../../context';

const Header = () => {
  const { userInfo } = useSporforyaContext();
  return (
    <div className="sticky top-0 z-20 w-full bg-white p-3 shadow-lg ">
      <div className="flex items-center justify-start md:justify-end gap-x-3">
        <Bell />
        <CircleUser />
        <div>
          <span className="block text-gray-700 text-sm font-medium">
            {userInfo?.firstName} {userInfo?.lastName}
          </span>
          <span className="block text-gray-700 text-sm font-medium">
            {userInfo?.email}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
