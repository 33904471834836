import { useCallback, useMemo, useRef, useState } from 'react';
import ProviderProfilePhoto from './ProviderProfilePhoto';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as BanIcon } from '../../assets/ban.svg';
import { ReactComponent as MessageIcon } from '../../assets/message.svg';
import { ReactComponent as OtpIcon } from '../../assets/otp-icon.svg';
import { ReactComponent as MailIcon } from '../../assets/mail.svg';
import { Provider, ProviderTable } from '../../models/provider';
import { BadgeCheck, BadgeX, MapPin } from 'lucide-react';
import useProviders from '../../pages/Providers/useProviders';
import Alert from '../Alert';
import Modal from '../Modal';
import { NotificationDialog } from '../NotificationDialog';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type ProviderDetailProps = {
  providerData: Provider;
};

const ProviderDetail = ({ providerData }: ProviderDetailProps) => {
  const currentAction = useRef<string>();
  const activeProvider = useRef<Provider>();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState<ProviderTable[]>(
    [],
  );

  const navigate = useNavigate();

  const {
    mailProvider,
    banProvider,
    applyFilters,
    banMultipleProvider,
    deleteMultipleProviders,
    deleteProvider,
    sendProviderMessage,
  } = useProviders();

  const onCloseAlert = () => {
    setDisplayAlert(false);
    activeProvider.current = undefined;
    currentAction.current = undefined;
  };

  const onCloseModal = () => {
    setDisplayModal(false);
    activeProvider.current = undefined;
    currentAction.current = undefined;
  };

  const handleAlertAction = () => {
    if (!currentAction.current) {
      return;
    }

    switch (currentAction.current) {
      case 'delete':
        handleDeleteProvider();
        return;
      case 'ban':
        handleBanProvider();
        return;
      case 'mail':
        handleMailProvider();
        return;
      default:
        return;
    }
  };

  const handleDeleteProvider = () => {
    const currentProvider = activeProvider.current;
    if (!currentProvider) {
      return;
    }
    deleteProvider([currentProvider.id]);
    setDisplayAlert(false);
    window.location.reload();
  };

  const handleBanProvider = () => {
    const currentProvider = activeProvider.current;
    if (!currentProvider) {
      return;
    }
    if (
      currentProvider?.hasActiveBooking ||
      currentProvider?.hasUpcomingBooking
    ) {
      navigate(`/bookings`);
    } else {
      banProvider([currentProvider.id]);

      window.location.reload();
    }
  };

  const handleMailProvider = () => {
    const currentProvider = activeProvider.current;
    if (!currentProvider) {
      return;
    }
    const { email, firstName, lastName } = currentProvider;
    mailProvider(email, firstName, lastName);
    toast.success('Email has been sent to the provider');
    setDisplayAlert(false);
  };

  const handleDeleteAlertProvider = (row: Provider) => {
    setAlertDescription(`Are you sure you want to delete this provider?`);
    setDisplayAlert(true);
    activeProvider.current = row;
    currentAction.current = 'delete';
  };

  const handleBanAlertProvider = (row: Provider) => {
    if (row?.isBanned) {
      return;
    }
    setAlertDescription(
      `${
        row?.hasActiveBooking || row?.hasUpcomingBooking
          ? 'This provider has active booking. Please cancel those first. Do you want to proceed to booking page?'
          : `Are you sure you want to ban this provider?`
      }`,
    );
    setDisplayAlert(true);
    activeProvider.current = row;
    currentAction.current = 'ban';
  };

  const handleMailAlertProvider = (row: Provider) => {
    // if (row?.status !== 'Incompleted') {
    //   return;
    // }
    setAlertDescription('Are you sure you want to send the Mail?');
    setDisplayAlert(true);
    activeProvider.current = row;
    currentAction.current = 'mail';
  };
  const handleToggleDialog = () => {
    setToggleDialog(prev => !prev);
  };

  const handleSendNotification = (message: string) => {
    handleToggleDialog();
    const providers = !!activeProvider.current
      ? [activeProvider.current]
      : selectedProviders;
    activeProvider.current = undefined;
    setSelectedProviders([]);
    sendProviderMessage(
      message,
      providers.map(provider => provider.id),
    );
  };

  const handleViewOtp = (row: Provider) => {
    setModalDescription(
      `Sign up Otp: ${row?.signUpOtp ?? 'N/A'}. Phone Update Otp: ${
        row?.phoneUpdateOtp ?? row?.signUpOtp ?? 'N/A' // This needs to removed after BE solve the update phone otp issue
      }. Two Factor Otp: ${row?.twoFaOtp ?? 'N/A'}`,
    );
    setDisplayModal(true);
    activeProvider.current = row;
    currentAction.current = 'otp';
  };

  const handleMessage = (row: Provider) => {
    activeProvider.current = row;
    handleToggleDialog();
  };

  const renderActions = (row: Provider) => (
    <>
      <MailIcon
        title="Email Provider"
        className={`mr-2`}
        onClick={() => handleMailAlertProvider(row)}
      />
      <DeleteIcon
        title="Delete Provider"
        className="mr-2 fill-[#d63737] cursor-pointer"
        onClick={() => handleDeleteAlertProvider(row)}
      />
      <BanIcon
        title="Ban Provider"
        className={`mr-2 ${
          row?.isBanned
            ? 'disabled fill-[#939090] cursor-not-allowed'
            : 'block fill-[#d63737] cursor-pointer'
        }`}
        onClick={() => handleBanAlertProvider(row)}
      />
      <MessageIcon
        title="Message Provider"
        className="mr-2 fill-[#37a4d6] cursor-pointer"
        onClick={() => handleMessage(row)}
      />
      <OtpIcon
        title="View Otp"
        className="fill-primary-main cursor-pointer w-5 pl-1"
        onClick={() => handleViewOtp(row)}
      />
    </>
  );

  return (
    <>
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertDescription}
        />
      )}

      {displayModal && <Modal onClose={onCloseModal} body={modalDescription} />}

      {toggleDialog && (
        <NotificationDialog
          onClose={handleToggleDialog}
          placeholder="Message to provider"
          onSubmit={handleSendNotification}
        />
      )}
      <div className="relative mx-auto space-y-10">
        <div className="z-20 fixed bg-white shadow-lg w-20 h-auto top-20 right-10 text-center p-2">
          <p className="text-sm text-gray-600 font-bold">Actions</p>
          <div className="flex flex-col items-center justify-center gap-2 my-4">
            {renderActions?.(providerData)}
          </div>
        </div>
        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Basic Info
          </p>
          <div className="flex items-center gap-4 my-4">
            <ProviderProfilePhoto
              profileUrl={providerData?.user?.profilePictureUrl}
              firstName={providerData?.user?.fullName}
            />
            <div>
              <p className="text-lg capitalize font-semibold">
                {providerData?.user?.fullName}
              </p>
              <p className="text-sm">{providerData?.user?.email}</p>
              <p>
                {providerData?.user?.emailVerified ? (
                  <div className=" flex items-center gap-2">
                    <p className="text-sm text-blue-400">Verified</p>
                    <BadgeCheck size={15} />
                  </div>
                ) : (
                  <div className=" flex items-center gap-2">
                    <p className="text-sm text-red-400">Unverified</p>
                    <BadgeX size={15} />
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4 my-10">
          <div className="bg-white shadow-lg px-6 py-2 rounded-md text-center">
            <p>Total Listing</p>
            <p>{providerData?.listing?.length ?? 0}</p>
          </div>
          <div className="bg-white shadow-lg px-6 py-2 rounded-md text-center">
            <p>User Rating</p>
            <p>{0}</p>
          </div>
        </div>
        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Personal Information
          </p>
          <div className="text-md my-4 flex items-center gap-8 justify-stretch">
            <div className="mb-2">
              <p className="basis-[160px]  font-bold">Position/Title</p>
              <p>N/A</p>
            </div>
            <div className="mb-2">
              <p className="basis-[160px]  font-bold">Mobile Number</p>
              <p>N/A</p>
            </div>
          </div>
        </div>
        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Preovider Information
          </p>
          <div className="text-md my-4 flex items-center gap-8 justify-stretch">
            <div className=" mb-2 ">
              <p className="basis-[160px] font-bold">Provider Type</p>
              <p>N/A</p>
            </div>
            <div className="mb-2 ">
              <p className="basis-[160px]  font-bold">Website</p>
              <p>{providerData?.website}</p>
            </div>
            <div className="mb-2">
              <p className="basis-[160px]  font-bold">Introduction/Bio</p>
              <p>{providerData?.bio}</p>
            </div>
            <div className="mb-2">
              <p className="basis-[160px]  font-bold">Provider Description</p>
              <p>{providerData?.description}</p>
            </div>
          </div>
        </div>

        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Photos
          </p>
          <div className="flex gap-4 mt-3">
            {providerData?.imageUrls?.map((image, i) => (
              <img
                key={i}
                alt="image"
                src={image}
                className="w-[200px] h-[150px] rounded-md"
              />
            ))}
          </div>
        </div>

        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Social Media
          </p>
          <div>N/A</div>
        </div>

        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Location
          </p>
          <div>
            <p className="flex items-center space-y-4">
              <MapPin size={18} />
              Address
            </p>
            <p>N/A</p>
            <iframe
              title="Listing Loocation"
              src={`https://maps.google.com/maps?q=&t=&z=13&ie=UTF8&iwloc=&output=embed`}
              width="400"
              height="300"
              loading="lazy"></iframe>
          </div>
        </div>

        {/* <div>
      <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">Banned</p>
            <p className="flex gap-1">
              {providerData?.isBanned ? (
                <BannedIcon className="text-red-600 w-[20px]" />
              ) : (
                <CrossCircleIcon className="text-red-600 w-[20px]" />
              )}
              <span className="text-success-main">
                {providerData?.isBanned ? 'Banned' : 'No'}
              </span>
            </p>
          </div>
          <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">Online:</p>
            <p className="flex gap-1">
              {providerData?.isOnline ? (
                <CheckCircleIcon className="text-green-600 w-[20px]" />
              ) : (
                <CrossCircleIcon className="text-red-600 w-[20px]" />
              )}
              <span className="text-success-main">
                {providerData?.isOnline ? 'Yes' : 'No'}
              </span>
            </p>
          </div>
          <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">
              Varification Badge Eligible:
            </p>
            <p className="flex gap-1">
              {providerData?.isVerificationBadgeEligible ? (
                <ShieldCheckIcon className="text-green-600 w-[20px]" />
              ) : (
                <ShieldExclamationIcon className="text-red-600 w-[20px]" />
              )}
              <span className="text-success-main">
                {providerData?.isVerificationBadgeEligible ? 'Yes' : 'No'}
              </span>
            </p>
          </div>
      </div> */}

        {/* <div>
        <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
          Activity
        </p>
        <div className="flex gap-8 sm:gap-6 mt-3">
          <div>
            <p className="text-xs text-gray-500">Bookings</p>
            <p className="text-2xl font-semibold">
              {providerData?.bookingCount}
            </p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Sporty Points</p>
            <p className="text-2xl font-semibold">
              {providerData?.sportypoint}
            </p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Money Spent</p>
            <p className="text-2xl font-semibold">{formattedAmount}</p>
          </div>
        </div>
      </div> */}
      </div>
    </>
  );
};

export default ProviderDetail;
