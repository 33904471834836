import { TableColumn } from '../components/ui/Table';
import { Reports, ReportTable } from '../models/reports';

const ReportsColumn: TableColumn<ReportTable>[] = [
  { id: 'reportedBy', label: 'Reported by' },
  { id: 'reported', label: 'Reported' },
  { id: 'dateReport', label: 'Date of report' },
  { id: 'category', label: 'category' },
  { id: 'actions', label: 'Actions' },
];

const formatDate = (date: string) => {
  const dateTime = new Date(date);
  return `${dateTime.getUTCMonth()}/${dateTime.getUTCDate()}/${dateTime.getUTCFullYear()}`;
};

export const ReportToReportTable = (providers: Reports[]) => {
  const data: ReportTable[] = providers.map(
    ({ _id, category, createdAt, reportedBy, reportedTo }, index) => ({
      id: _id,
      serial: (index + 1).toString(),
      category,
      reportedBy: reportedBy
        ? `${reportedBy.firstName} ${reportedBy.lastName}`
        : '',
      reported: reportedTo
        ? `${reportedTo.firstName} ${reportedTo.lastName}`
        : '',
      dateReport: formatDate(createdAt),
      actions: 'ACTIONS',
    }),
  );
  return { headers: ReportsColumn, data };
};
