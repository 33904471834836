import { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as MessageIcon } from '../../assets/message.svg';
import Pagination from '../../components/Pagination';
import StatusHandler from '../../components/StatusHandler';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import Table, { TableColumn } from '../../components/ui/Table';
import { ReviewTable } from '../../models/reviews';
import { ReviewToReviewTable } from '../../utils/reviews';
import useReviews from './useReviews';
import Dialog from '../../components/Dialog';
import Alert from '../../components/Alert';
import NewTable from '../../components/ui/NewTable';
import BreadCrumbs from '../../components/BreadCrumbs';

const DateOrder = [
  {
    id: 'desc',
    value: 'Newest first',
  },
  {
    id: 'asc',
    value: 'Oldest first',
  },
];

const Reviews = () => {
  const selectedReviewRef = useRef<ReviewTable>();
  const [userMessage, setUserMessage] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);
  const [toggleDialog, setToggleDialog] = useState(false);
  const [filters, setFilters] = useState<{
    sortBy?: DropdownOption;
    search?: string;
  }>({
    sortBy: DateOrder[0],
  });
  const [selectedSort, setSelectedSort] = useState(DateOrder[0]);
  const [reviewsData, setReviewsData] = useState<{
    headers: TableColumn<ReviewTable>[];
    data: ReviewTable[];
  }>({ headers: [], data: [] });
  const {
    reviews,
    isLoading,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    applyFilters,
    sendUserMessage,
    deleteReview,
    error,
  } = useReviews({ order: 'desc' });

  console.log({ reviews });
  useEffect(() => {
    if (reviews) {
      setReviewsData(ReviewToReviewTable(reviews));
    }
  }, [reviews]);

  const handleSortByDate = (option: DropdownOption) => {
    setSelectedSort(option);
    setFilters(prev => ({ ...prev, sortBy: option }));
    applyFilters({ order: option.id });
  };

  const handleSearch = (value: string) => {
    setFilters(prev => ({ ...prev, search: value }));
    applyFilters({ search: value });
  };

  const handleMessage = (row: ReviewTable) => {
    selectedReviewRef.current = row;
    handleToggleDialog();
  };

  const handleDeleteAlert = (row: ReviewTable) => {
    setDisplayAlert(true);
    selectedReviewRef.current = row;
  };

  const renderActions = (row: ReviewTable) => (
    <>
      <DeleteIcon
        title="Delete Review"
        className="mr-2 fill-[#d63737] cursor-pointer"
        onClick={() => handleDeleteAlert(row)}
      />
      <MessageIcon
        title="Message User"
        className="mr-2 fill-[#37a4d6] cursor-pointer"
        onClick={() => handleMessage(row)}
      />
    </>
  );

  const handleToggleDialog = () => {
    setToggleDialog(prev => !prev);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserMessage(e.target.value);
  };

  const handleSubmitMessage = () => {
    if (!selectedReviewRef.current) {
      return;
    }
    handleToggleDialog();
    sendUserMessage(userMessage, selectedReviewRef.current?.id);
    setUserMessage('');
    selectedReviewRef.current = undefined;
  };
  const handleAlertAction = () => {
    const currentReview = selectedReviewRef.current;
    if (!currentReview) {
      return;
    }
    deleteReview(currentReview.id);
    const data = reviewsData.data.reduce((all, review) => {
      if (review.id !== currentReview.id) {
        all.push(review);
      }
      return all;
    }, [] as ReviewTable[]);

    setReviewsData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const onCloseAlert = () => {
    setDisplayAlert(false);
    selectedReviewRef.current = undefined;
  };

  const handleResetFilters = () => {
    setFilters({ sortBy: DateOrder[0] });
    applyFilters({ order: DateOrder[0].id });
  };

  const renderSearch = [
    {
      id: 1,
      placeholder: 'Search USR/ PRV name or Listing ID',
      value: filters?.search,
      onInput: handleSearch,
    },
  ];

  const renderFilters = [
    {
      id: 1,
      label: 'Date',
      defaultValue: filters?.sortBy,
      options: DateOrder,
      action: handleSortByDate,
    },
  ];

  const downloadCSV = {
    fileName: 'Sporti Reviews',
    data: reviewsData.data,
  };

  const breadCrumbs = [
    {
      displayName: 'Reviews',
    },
  ];

  return (
    <>
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title="Are you sure you want to delete these review?"
        />
      )}
      {toggleDialog && (
        <Dialog
          className="w-[90%] md:w-[600px] h-[420px] justify-around"
          onClose={handleToggleDialog}>
          <div>
            <h1 className="text-2xl text-center mb-3">Send a Message</h1>
            <p>
              The user will receive a notifications in the app OR on their email
              account.
            </p>
          </div>
          <form className="w-full" onSubmit={handleSubmitMessage}>
            <textarea
              required
              value={userMessage}
              onChange={handleMessageChange}
              placeholder="Message for the user"
              className="w-full focus:outline-none mb-4 focus:shadow-none focus:border-none focus:ring-0 text-secondary-main border-none max-h-40 md:max-h-[200px] h-40 bg-[#f6f8f96e] placeholder:text-[#b7b7b7]"
            />
            <Button onClick={handleSubmitMessage} text="Send" type="submit" />
          </form>
        </Dialog>
      )}
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide">
        <h1 className="text-secondary-main text-[50px] my-6 mr-8 md:mr-0">
          Reviews & Ratings
        </h1>
        <BreadCrumbs breadcrumbs={breadCrumbs} />
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <NewTable
              data={reviewsData.data}
              columns={reviewsData.headers}
              getActions={renderActions}
              currentPage={currentPage}
              filterable
              filters={renderFilters}
              searchable
              search={renderSearch}
              reset={handleResetFilters}
              downloadCSV={downloadCSV}
            />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default Reviews;
