import { Navigate } from 'react-router-dom';
import { useSporforyaContext } from '../../context';

type Props = {
  children: JSX.Element;
};

const Protected = ({ children }: Props) => {
  const { token } = useSporforyaContext();
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};
export default Protected;
