import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import { Bookings, BookingTable } from '../models/bookings';

const BookingColumn: TableColumn<BookingTable>[] = [
  { id: 'id', label: 'ID' },
  { id: 'status', label: 'Status' },
  { id: 'bookedBy', label: 'Booked By' },
  { id: 'listingId', label: 'Listing Id' },
  { id: 'providerName', label: 'Provider Name' },
  { id: 'createdAt', label: 'Date' },
];

const getTableHeader = (readOnly: boolean) => {
  return readOnly
    ? BookingColumn
    : ([
        ...BookingColumn,
        { id: 'actions', label: 'Actions' },
      ] as TableColumn<BookingTable>[]);
};
export const BookingToBookingTable = (
  providers: Bookings[],
  readOnly = false,
) => {
  const data: BookingTable[] = providers.map(
    ({ id, user, listingId, provider, status, listing, createdAt }, index) => {
      const values: BookingTable = {
        id: id,
        serial: (index + 1).toString(),
        status:
          status === 'active'
            ? 'ongoing'
            : status === 'past'
            ? 'completed'
            : status === 'dispute' || status === 'partial-cancel'
            ? 'under-review'
            : status,
        bookedBy: user ? user?.fullName : '',
        listingId,
        providerName: listing?.provider?.user?.fullName
          ? listing?.provider?.user?.fullName
          : '',
        createdAt: format(
          createdAt ? new Date(createdAt) : new Date(),
          'MM-dd-yyy hh:mm a',
        ),
      };
      if (!readOnly) {
        values.actions = 'ACTIONS';
      }
      return values;
    },
  );
  return {
    headers: getTableHeader(readOnly),
    data,
  };
};
