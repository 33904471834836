import { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import { ReactComponent as BanIcon } from '../../assets/ban.svg';
import { ReactComponent as MessageIcon } from '../../assets/message.svg';
import Pagination from '../../components/Pagination';
import StatusHandler from '../../components/StatusHandler';
import Button from '../../components/ui/Button';
import Table, { TableColumn } from '../../components/ui/Table';
import Dialog from '../../components/Dialog';
import Alert from '../../components/Alert';
import { ReportTable } from '../../models/reports';
import useReports from './useReports';
import { ReportToReportTable } from '../../utils/reports';
import { toast } from 'react-toastify';

const DateOrder = [
  {
    id: 'desc',
    value: 'Newest first',
  },
  {
    id: 'asc',
    value: 'Oldest first',
  },
];

const Reports = () => {
  const selectedReportRef = useRef<ReportTable>();
  const [userMessage, setUserMessage] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);
  const [toggleDialog, setToggleDialog] = useState(false);
  const [filters, setFilters] = useState<{
    sortBy?: DropdownOption;
  }>({
    sortBy: DateOrder[0],
  });
  const [selectedSort, setSelectedSort] = useState(DateOrder[0]);
  const [reportsData, setReportsData] = useState<{
    headers: TableColumn<ReportTable>[];
    data: ReportTable[];
  }>({ headers: [], data: [] });
  const {
    reports,
    isLoading,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    applyFilters,
    sendUserMessage,
    banUser,
    error,
  } = useReports({ sortBy: 'desc' });

  useEffect(() => {
    if (reports) {
      setReportsData(ReportToReportTable(reports));
    }
  }, [reports]);

  const handleSortByDate = (option: DropdownOption) => {
    setSelectedSort(option);
    setFilters(prev => ({ ...prev, sortBy: option }));
    applyFilters({ sortBy: option.id });
  };

  const handleMessage = (row: ReportTable) => {
    selectedReportRef.current = row;
    handleToggleDialog();
  };

  const handleBanAlert = (row: ReportTable) => {
    setDisplayAlert(true);
    selectedReportRef.current = row;
  };

  const renderActions = (row: ReportTable) => (
    <>
      <BanIcon
        title="Ban user"
        className="mr-2 fill-[#d63737] cursor-pointer"
        onClick={() => handleBanAlert(row)}
      />
      <MessageIcon
        title="Message User"
        className="mr-2 fill-[#37a4d6] cursor-pointer"
        onClick={() => handleMessage(row)}
      />
    </>
  );

  const handleToggleDialog = () => {
    setToggleDialog(prev => !prev);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserMessage(e.target.value);
  };

  const handleSubmitMessage = () => {
    if (!selectedReportRef.current) {
      return;
    }
    handleToggleDialog();
    const report = reports?.find(
      report => report._id === selectedReportRef.current?.id,
    );
    if (!report) return;
    // TODO BE should fix this, there is some reportedTo with null
    sendUserMessage(userMessage, report.reportedTo?._id || '');
    toast.success('Message sent');
    setUserMessage('');
    selectedReportRef.current = undefined;
  };
  const handleAlertAction = () => {
    const currentReview = selectedReportRef.current;
    if (!currentReview) {
      return;
    }
    const report = reports?.find(
      report => report._id === selectedReportRef.current?.id,
    );
    if (!report) return;
    banUser(report.reportedTo?._id || '');
    toast.success('User was banned');
    const data = reportsData.data.reduce((all, report) => {
      if (report.id !== currentReview.id) {
        all.push(report);
      }
      return all;
    }, [] as ReportTable[]);

    setReportsData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const onCloseAlert = () => {
    setDisplayAlert(false);
    selectedReportRef.current = undefined;
  };

  const handleResetFilters = () => {
    setFilters({ sortBy: DateOrder[0] });
    applyFilters({ sortBy: DateOrder[0].id });
  };

  return (
    <>
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title="Are you sure you want to ban this user?"
        />
      )}
      {toggleDialog && (
        <Dialog
          className="w-[90%] md:w-[600px] h-[420px] justify-around"
          onClose={handleToggleDialog}>
          <div>
            <h1 className="text-2xl text-center mb-3">Send a Message</h1>
            <p>
              The user will receive a notifications in the app OR on their email
              account.
            </p>
          </div>
          <form className="w-full" onSubmit={handleSubmitMessage}>
            <textarea
              required
              value={userMessage}
              onChange={handleMessageChange}
              placeholder="Message for the user"
              className="w-full focus:outline-none mb-4 focus:shadow-none focus:border-none focus:ring-0 text-secondary-main border-none max-h-40 md:max-h-[200px] h-40 bg-[#f6f8f96e] placeholder:text-[#b7b7b7]"
            />
            <Button onClick={handleSubmitMessage} text="Send" type="submit" />
          </form>
        </Dialog>
      )}
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide">
        <h1 className="text-secondary-main text-[50px] my-6 mr-8 md:mr-0">
          Reports
        </h1>
        <div className="w-full flex flex-wrap gap-6 items-center mb-6">
          <div className="flex flex-wrap gap-6 items-center w-full min-[480px]:w-auto">
            <Dropdown
              placeholder="Date"
              className="w-full min-[480px]:w-[200px]"
              defaultValue={filters.sortBy}
              options={DateOrder}
              onSelectOption={handleSortByDate}
            />
          </div>
          <div className="flex flex-wrap gap-6 items-center w-full min-[480px]:w-auto">
            <Button
              className="w-full min-[480px]:w-[200px]"
              text="Reset"
              onClick={handleResetFilters}
            />
            <CSVLink
              className="w-full min-[480px]:w-[200px] text-center font-semibold text-primary-main justify-center"
              data={reportsData.data}
              filename="Sporti_Reviews">
              Download CSV
            </CSVLink>
          </div>
        </div>
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <Table
              data={reportsData?.data}
              columns={reportsData?.headers}
              getActions={renderActions}
              currentPage={currentPage}
            />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default Reports;
