import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import { Reviews, ReviewTable } from '../models/reviews';

const ReviewsColumn: TableColumn<ReviewTable>[] = [
  { id: 'reviewId', label: 'Review ID' },
  { id: 'listingId', label: 'Listing ID' },
  { id: 'review', label: 'Review' },
  { id: 'reviewGivenFor', label: 'Review Given For' },
  { id: 'sender', label: 'Sender' },
  { id: 'receiver', label: 'Receiver' },
  { id: 'createdAt', label: 'Date' },
  { id: 'actions', label: 'Actions' },
];

export const ReviewToReviewTable = (reviews: Reviews[]) => {
  const data: ReviewTable[] = reviews?.map(
    (
      {
        id,
        listingId,
        comment,
        reviewGivenFor,
        reviewedByIdUser,
        reviewedForIdUser,
        createdAt,
      },
      index,
    ) => ({
      id: id,
      serial: (index + 1).toString(),
      // bookingId: booking ? booking._id : '',
      // listingId: listing._id,
      reviewId: id,
      listingId: listingId,
      review: comment,
      reviewGivenFor: reviewGivenFor,
      sender: reviewedByIdUser?.fullName,
      receiver: reviewedForIdUser?.fullName,
      createdAt: format(
        createdAt ? new Date(createdAt) : new Date(),
        'MM-dd-yyy hh:mm a',
      ),
      actions: 'ACTIONS',
    }),
  );
  return { headers: ReviewsColumn, data };
};
