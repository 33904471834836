const useCache = () => {
  const getCacheData = <T,>(key: string) => {
    const data = localStorage.getItem(key);
    if (data && data !== 'undefined') {
      return JSON.parse(data) as T;
    }
    return undefined;
  };

  const setCacheData = <T,>(key: string, data: T) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const removeCacheData = (key: string) => {
    localStorage.removeItem(key);
  };

  return { getCacheData, setCacheData, removeCacheData };
};

export default useCache;
