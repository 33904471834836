import { useEffect, useState, useRef } from 'react';
import useApi from '../../../hooks/useApi';
import { UserResponse } from '../../../models/user';

const useUsers = (params?: Record<string, any>) => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall, error } = useApi<UserResponse | any>(
    'admin-panel/all/users',
  );
  const { launchCall: launchAction } = useApi('users');

  useEffect(() => {
    // Let's make automatically since by the moment
    // there is no other reason to not do it.
    getUsers(params);
  }, []);

  const getUsers = (
    params?: Record<string, string | number>,
    data?: Record<string, any>,
  ) => {
    try {
      applyFiltersRef.current = {
        ...applyFiltersRef.current,
        ...data,
        banned: true,
      };
      launchCall({ method: 'POST' }, { ...params, ...applyFiltersRef.current });
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilters = (
    sorting: { sortOrder?: string; searchString?: string; status?: string },
    reset?: boolean,
  ) => {
    if (reset) {
      applyFiltersRef.current = {};
    }

    getUsers(params, { ...sorting, skip: 0 });
  };

  const handlePagination = (page: number, offset: number) => {
    getUsers({ ...params, page: page, limit: offset });
  };

  const nextPage = () => {
    const nextPage = response?.meta.currentPage + 1;
    if (nextPage <= Math.ceil(response?.meta?.total / response.meta.limit)) {
      handlePagination(nextPage, response.meta.limit);
    }
  };

  const prevPage = () => {
    const prevPage = response?.meta.currentPage - 1;
    if (prevPage > 0) {
      handlePagination(prevPage, response.meta.limit);
    }
  };

  const sendUserMessage = (message: string, userIds: string[]) => {
    try {
      launchAction(
        {
          method: 'PATCH',
          body: JSON.stringify({
            userIds: userIds,
            messageText: message,
            type: 'NOTIFICATION',
          }),
        },
        undefined,
        `admin-panel/send/email-notifications`,
      );
    } catch (err) {
      console.log(err);
    }
  };
  const handleBanUser = async (id: string[]) => {
    await launchAction(
      { method: 'PATCH', body: JSON.stringify({ userIds: id }) },
      undefined,
      'admin-panel/banned/users',
    );
    getUsers();
  };

  const handleMailUser = async (
    email: string,
    firstName: string,
    lastName: string,
  ) => {
    await launchAction(
      { method: 'POST', body: JSON.stringify({ email, firstName, lastName }) },
      undefined,
      `admin/profile/user/send-incomplete-email`,
    );
    getUsers();
  };

  const handleDeleteUser = (userIds: string[]) => {
    launchAction(
      { method: 'DELETE', body: JSON.stringify({ userIds }) },
      undefined,
      `admin-panel/delete/users`,
    );
  };

  const handleBaneMultipleUser = (ids: string[]) => {
    launchAction(
      { method: 'PATCH', body: JSON.stringify({ userIds: ids }) },
      undefined,
      'admin-panel/banned/users',
    );
  };
  const handleDeleteMultipleUser = (ids: string[]) => {
    launchAction(
      { method: 'DELETE', body: JSON.stringify({ userIds: ids }) },
      undefined,
      'admin/delete-users',
    );
  };
  return {
    getUsers,
    nextPage,
    prevPage,
    banUser: handleBanUser,
    mailUser: handleMailUser,
    deleteUser: handleDeleteUser,
    banMultipleUser: handleBaneMultipleUser,
    deleteMultipleUsers: handleDeleteMultipleUser,
    applyFilters,
    sendUserMessage,
    users: response?.data,
    totalPages: Math.ceil(response?.meta?.total / response?.meta?.limit) || 0,
    currentPage: response?.meta?.currentPage || 0,
    isLoading,
    error: errorMessage,
  };
};

export default useUsers;
