import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import Pagination from '../../components/Pagination';
import StatusHandler from '../../components/StatusHandler';
import { TableColumn } from '../../components/ui/Table';
import { SubscriberDownladed, SubscriberTable } from '../../models/subscriber';
import {
  SubscriberDownloadedData,
  SubscriberToSubscriberTable,
} from '../../utils/subscribers';
import useSubscribedUsers from './useSubscribedUsers';
import NewTable from '../../components/ui/NewTable';

type Props = {
  userRole?: string;
};

const SubscribedUsers = ({ userRole = 'Subscriber' }: Props) => {
  const [usersData, setUsersData] = useState<{
    headers: TableColumn<SubscriberTable>[];
    data: SubscriberTable[];
  }>({ headers: [], data: [] });

  const [usersDownloadedData, setUsersDownloadedData] = useState<{
    data: SubscriberDownladed[];
  }>({ data: [] });
  const { users, isLoading, error } = useSubscribedUsers();

  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [totalPages, setTotalPages] = useState<number | undefined>();

  let currPage = 1;
  let contentPerPage = 20;

  const resetPageSize = () => {
    if (users) {
      setCurrentPage(1);
      setTotalPages(Math.ceil(users.length / contentPerPage));
    }
  };

  const findSlicingIndex = () => {
    const startIndex = currPage * contentPerPage - contentPerPage;
    const endIndex = startIndex + contentPerPage;
    return { startIndex, endIndex };
  };

  useEffect(() => {
    if (users) {
      resetPageSize();
      getPaginatedData();
      setUsersDownloadedData(SubscriberDownloadedData(users));
    }
  }, [users]);

  /* Local Pagination feature */

  const getPaginatedData = () => {
    let { startIndex, endIndex } = findSlicingIndex();
    const paginatedData = users.slice(startIndex, endIndex);
    setUsersData(SubscriberToSubscriberTable(paginatedData));
  };

  const nextPage = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      currPage = currentPage + 1;
      setCurrentPage(currPage);
      getPaginatedData();
    }
  };

  const prevPage = () => {
    if (currentPage && totalPages && currentPage > 1) {
      currPage = currentPage - 1;
      setCurrentPage(currPage);
      getPaginatedData();
    }
  };

  const downloadCSV = {
    fileName: "Sporti Users",
    data: usersDownloadedData.data,
  }

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide mb-4">
        <h1 className="text-secondary-main text-[50px] my-6 first-letter:uppercase">
          {userRole}
        </h1>
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <NewTable data={usersData?.data} columns={usersData?.headers} 
              downloadCSV={downloadCSV} />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default SubscribedUsers;
