import { createContext, useContext } from 'react';
import { User } from '../models/user';

export const initContext = {
  baseUrl: process.env.REACT_APP_BASE_URL || '',
  setToken: () => {},
  setUserInfo: () => {},
};

export interface SporforyaContextInfo {
  baseUrl: string;
  token?: string;
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  userInfo?: User;
  setUserInfo: React.Dispatch<React.SetStateAction<User | undefined>>;
}

export const SporforyaContext =
  createContext<SporforyaContextInfo>(initContext);

export const useSporforyaContext = () => useContext(SporforyaContext);
