import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import {
  Referrers,
  ReferrerTable,
} from '../models/referrer';

const ReferrerColumn: TableColumn<ReferrerTable>[] = [
  { id: 'id', label: 'ID' },
  { id: 'referred', label: 'Referred' },
  { id: 'refree', label: 'Refree' },
  { id: 'createdAt', label: 'Referred at' },
  { id: 'redeemed', label: 'Redeemed' },
];


export const ReferrerToReferrerTable = (referrers: Referrers[]) => {
  const data: any = referrers.map(
    ({ id, email, user:{email: refreeEmail}, createdAt, redeemed }, index) => ({
      id: id,
      serial: (index + 1).toString(),
      referred: email,
      refree: refreeEmail,
      createdAt: format(new Date(createdAt), 'MM-dd-yyy hh:mm a'),
      redeemed: redeemed ? "✅" : "❌",
    }),
  );
  return { headers: ReferrerColumn, data };
};

export const ReferrerDownloadedData = (referrers: any) => {
  return { data: referrers };
};
