import { ReactComponent as LoaderIcon } from '../../assets/loader.svg';

type Props = {
  className?: string;
  svgStyle?: string;
};

const Loader = ({ className, svgStyle }: Props) => {
  return (
    <div className={`${className}`}>
      <LoaderIcon className={`animate-spin ${svgStyle}`} />
    </div>
  );
};

export default Loader;
