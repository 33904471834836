import { useEffect, useState, useRef } from 'react';
import useApi from '../../../hooks/useApi';
import { ReportResponse } from '../../../models/reports';

const useReports = (defaultParams?: { sortBy: string }) => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall } = useApi<ReportResponse>(
    'admin/get-all-reports',
  );
  const { launchCall: launchActionCall } = useApi('admin/ban-users');

  useEffect(() => {
    // Let's make automatically since by the moment
    // there is no other reason to not do it.
    getReports(defaultParams);
  }, []);

  const getReports = (params?: Record<string, any>) => {
    try {
      applyFiltersRef.current = { ...applyFiltersRef.current, ...params };
      launchCall({ method: 'GET' }, applyFiltersRef.current);
    } catch (err) {
      console.log(err);
    }
  };

  const sendUserMessage = (message: string, userId: string) => {
    try {
      launchActionCall(
        {
          method: 'POST',
          body: JSON.stringify({
            userIds: [userId],
            messageText: message,
          }),
        },
        undefined,
        `admin/v2/send-notification-or-email-to-users`,
      );
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilters = (sorting: { sortBy?: string }) => {
    getReports({ ...{ ...sorting }, skip: 0 });
  };

  const handleBanUser = (id: string) => {
    launchActionCall(
      { method: 'POST', body: JSON.stringify({ userIds: [id] }) },
      undefined,
      `admin/ban-users`,
    );
  };

  const handlePagination = (page: number, offset: number) => {
    const identifier = page - 1;
    getReports({ skip: identifier * offset });
  };

  const nextPage = () => {
    const nextPage = response?.meta.nextPage;
    if (nextPage) {
      handlePagination(nextPage, response.meta.limit);
    }
  };

  const prevPage = () => {
    const prevPage = response?.meta.prevPage;
    if (prevPage) {
      handlePagination(prevPage, response.meta.limit);
    }
  };

  return {
    getReports,
    nextPage,
    prevPage,
    applyFilters,
    sendUserMessage,
    banUser: handleBanUser,
    reports: response?.docs,
    totalPages: response?.meta.totalPages,
    currentPage: response?.meta.page,
    error: errorMessage,
    isLoading,
  };
};

export default useReports;
