import { useCallback, useMemo, useRef, useState } from 'react';
import UserProfilePhoto from './UserProfilePhoto';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as BanIcon } from '../../assets/ban.svg';
import { ReactComponent as MessageIcon } from '../../assets/message.svg';
import { ReactComponent as OtpIcon } from '../../assets/otp-icon.svg';
import { ReactComponent as MailIcon } from '../../assets/mail.svg';
import { User, UserTable } from '../../models/user';
import { BadgeCheck, BadgeX, MapPin } from 'lucide-react';
import useUsers from '../../pages/Users/useUsers';
import Alert from '../Alert';
import Modal from '../Modal';
import { NotificationDialog } from '../NotificationDialog';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type UserDetailProps = {
  userData: User;
};

const UserDetail = ({ userData }: UserDetailProps) => {
  const currentAction = useRef<string>();
  const activeUser = useRef<User>();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<UserTable[]>([]);

  console.log({ userData });

  const navigate = useNavigate();

  const {
    mailUser,
    banUser,
    applyFilters,
    banMultipleUser,
    deleteMultipleUsers,
    deleteUser,
    sendUserMessage,
  } = useUsers();

  const onCloseAlert = () => {
    setDisplayAlert(false);
    activeUser.current = undefined;
    currentAction.current = undefined;
  };

  const onCloseModal = () => {
    setDisplayModal(false);
    activeUser.current = undefined;
    currentAction.current = undefined;
  };

  const handleAlertAction = () => {
    if (!currentAction.current) {
      return;
    }

    switch (currentAction.current) {
      case 'delete':
        handleDeleteUser();
        return;
      case 'ban':
        handleBanUser();
        return;
      case 'mail':
        handleMailUser();
        return;
      default:
        return;
    }
  };

  const handleDeleteUser = () => {
    const currentUser = activeUser.current;
    if (!currentUser) {
      return;
    }
    deleteUser([currentUser.id]);
    setDisplayAlert(false);
    window.location.reload();
  };

  const handleBanUser = () => {
    const currentUser = activeUser.current;
    if (!currentUser) {
      return;
    }
    if (currentUser?.hasActiveBooking || currentUser?.hasUpcomingBooking) {
      navigate(`/bookings`);
    } else {
      banUser([currentUser.id]);

      window.location.reload();
    }
  };

  const handleMailUser = () => {
    const currentUser = activeUser.current;
    if (!currentUser) {
      return;
    }
    const { email, firstName, lastName } = currentUser;
    mailUser(email, firstName, lastName);
    toast.success('Email has been sent to the user');
    setDisplayAlert(false);
  };

  const handleDeleteAlertUser = (row: User) => {
    setAlertDescription(`Are you sure you want to delete this user?`);
    setDisplayAlert(true);
    activeUser.current = row;
    currentAction.current = 'delete';
  };

  const handleBanAlertUser = (row: User) => {
    if (row?.isBanned) {
      return;
    }
    setAlertDescription(
      `${
        row?.hasActiveBooking || row?.hasUpcomingBooking
          ? 'This user has active booking. Please cancel those first. Do you want to proceed to booking page?'
          : `Are you sure you want to ban this user?`
      }`,
    );
    setDisplayAlert(true);
    activeUser.current = row;
    currentAction.current = 'ban';
  };

  const handleMailAlertUser = (row: User) => {
    // if (row?.status !== 'Incompleted') {
    //   return;
    // }
    setAlertDescription('Are you sure you want to send the Mail?');
    setDisplayAlert(true);
    activeUser.current = row;
    currentAction.current = 'mail';
  };
  const handleToggleDialog = () => {
    setToggleDialog(prev => !prev);
  };

  const handleSendNotification = (message: string) => {
    handleToggleDialog();
    const users = !!activeUser.current ? [activeUser.current] : selectedUsers;
    activeUser.current = undefined;
    setSelectedUsers([]);
    sendUserMessage(
      message,
      users.map(user => user.id),
    );
  };

  const handleViewOtp = (row: User) => {
    setModalDescription(
      `Sign up Otp: ${row?.signUpOtp ?? 'N/A'}. Phone Update Otp: ${
        row?.phoneUpdateOtp ?? row?.signUpOtp ?? 'N/A' // This needs to removed after BE solve the update phone otp issue
      }. Two Factor Otp: ${row?.twoFaOtp ?? 'N/A'}`,
    );
    setDisplayModal(true);
    activeUser.current = row;
    currentAction.current = 'otp';
  };

  const handleMessage = (row: User) => {
    activeUser.current = row;
    handleToggleDialog();
  };

  const renderActions = (row: User) => (
    <>
      <MailIcon
        title="Email User"
        className={`mr-2`}
        onClick={() => handleMailAlertUser(row)}
      />
      <DeleteIcon
        title="Delete User"
        className="mr-2 fill-[#d63737] cursor-pointer"
        onClick={() => handleDeleteAlertUser(row)}
      />
      <BanIcon
        title="Ban User"
        className={`mr-2 ${
          row?.isBanned
            ? 'disabled fill-[#939090] cursor-not-allowed'
            : 'block fill-[#d63737] cursor-pointer'
        }`}
        onClick={() => handleBanAlertUser(row)}
      />
      <MessageIcon
        title="Message User"
        className="mr-2 fill-[#37a4d6] cursor-pointer"
        onClick={() => handleMessage(row)}
      />
      <OtpIcon
        title="View Otp"
        className="fill-primary-main cursor-pointer w-5 pl-1"
        onClick={() => handleViewOtp(row)}
      />
    </>
  );

  return (
    <>
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertDescription}
        />
      )}

      {displayModal && <Modal onClose={onCloseModal} body={modalDescription} />}

      {toggleDialog && (
        <NotificationDialog
          onClose={handleToggleDialog}
          placeholder="Message to user"
          onSubmit={handleSendNotification}
        />
      )}
      <div className="relative mx-auto space-y-10">
        <div className="z-20 fixed bg-white shadow-lg w-20 h-auto top-20 right-10 text-center p-2">
          <p className="text-sm text-gray-600 font-bold">Actions</p>
          <div className="flex flex-col items-center justify-center gap-2 my-4">
            {renderActions?.(userData)}
          </div>
        </div>
        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Basic Info
          </p>
          <div className="flex items-center gap-4 my-4">
            <UserProfilePhoto
              profileUrl={userData?.profilePictureUrl}
              firstName={userData?.fullName}
            />
            <div>
              <p className="text-lg capitalize font-semibold">
                {userData?.fullName}
              </p>
              <p className="text-sm">{userData?.email}</p>
              <p>
                {userData?.emailVerified ? (
                  <div className=" flex items-center gap-2">
                    <p className="text-sm text-blue-400">Verified</p>
                    <BadgeCheck size={15} />
                  </div>
                ) : (
                  <div className=" flex items-center gap-2">
                    <p className="text-sm text-red-400">Unverified</p>
                    <BadgeX size={15} />
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="flex items-center gap-4 my-10">
          <div className="bg-white shadow-lg px-6 py-2 rounded-md text-center">
            <p>Total Listing</p>
            <p>{userData?.listing?.length ?? 0}</p>
          </div>
          <div className="bg-white shadow-lg px-6 py-2 rounded-md text-center">
            <p>User Rating</p>
            <p>{0}</p>
          </div>
        </div> */}
        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Personal Information
          </p>
          <div className="text-md my-4 flex items-center gap-8 justify-stretch">
            <div className="mb-2">
              <p className="basis-[160px]  font-bold">Position/Title</p>
              <p>N/A</p>
            </div>
            <div className="mb-2">
              <p className="basis-[160px]  font-bold">Mobile Number</p>
              <p>{userData?.twoFAMobileNumber ?? 'N/A'}</p>
            </div>
          </div>
        </div>
        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Emergency Contact
          </p>
          <div className="text-md my-4 flex items-center gap-8 justify-stretch">
            <div className=" mb-2 ">
              <p className="basis-[160px] font-bold">Full Name</p>
              <p>N/A</p>
            </div>
            <div className="mb-2">
              <p className="basis-[160px]  font-bold">Mobile Number</p>
              <p>N/A</p>
            </div>
            <div className="mb-2">
              <p className="basis-[160px]  font-bold">Email</p>
              <p>N/A</p>
            </div>
          </div>
        </div>

        <div>
          <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
            Location
          </p>
          <div>
            <p className="flex items-center space-y-4">
              <MapPin size={18} />
              Address
            </p>
            <p>N/A</p>
            <iframe
              title="Listing Loocation"
              src={`https://maps.google.com/maps?q=&t=&z=13&ie=UTF8&iwloc=&output=embed`}
              width="400"
              height="300"
              loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetail;
