import { Link } from 'react-router-dom';
import { BreadcrumbItem } from '../../types/BreadcrumbItem';
import { ReactComponent as ChevronRightIcon } from '../../assets/chevron-right.svg';

type BreadCrumbsProps = {
  breadcrumbs: BreadcrumbItem[];
};

const BreadCrumbs = ({ breadcrumbs }: BreadCrumbsProps) => {
  return (
    <div>
      <ul className="flex items-center">
        <li className="text-lg text-[#6f6f6f] ml-1 flex items-center">
          <Link to="/">Home</Link>
          <ChevronRightIcon className="ml-1 w-[12px] h-[12px]" />
        </li>
        {breadcrumbs.map((breadcrumbItem, index) => (
          <li
            key={index}
            className="text-lg text-[#6f6f6f] ml-1 flex items-center">
            {breadcrumbItem.link ? (
              <Link to={breadcrumbItem.link}>{breadcrumbItem.displayName}</Link>
            ) : (
              <span className="text-xl font-bold">
                {breadcrumbItem.displayName}
              </span>
            )}
            {index + 1 < breadcrumbs.length && (
              <ChevronRightIcon className="ml-1 w-[12px] h-[12px]" />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BreadCrumbs;
