import { useEffect, useState, useRef } from 'react';
import useApi from '../../../hooks/useApi';
import { ProviderResponse } from '../../../models/provider';

const useProviders = (params?: Record<string, any>) => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall, error } = useApi<ProviderResponse | any>(
    'admin-panel/all/providers',
  );
  const { launchCall: launchAction } = useApi('providers');

  useEffect(() => {
    // Let's make automatically since by the moment
    // there is no other reason to not do it.
    getProviders(params);
  }, []);



  const getProviders = (
    params?: Record<string, string | number>,
    data?: Record<string, any>,
  ) => {
    try {
      applyFiltersRef.current = { ...applyFiltersRef.current, ...data };
      launchCall({ method: 'POST', body: JSON.stringify(data) }, { ...params, ...applyFiltersRef.current });
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilters = (
    sorting: { sortOrder?: string; searchString?: string; status?: string },
    reset?: boolean,
  ) => {
    if (reset) {
      applyFiltersRef.current = {};
    }

    getProviders(params, { ...sorting, skip: 0 });
  };

  const handlePagination = (page: number, offset: number) => {
    getProviders({ ...params, page: page, limit: offset });
  };

  const nextPage = () => {
    const nextPage = response?.meta.currentPage + 1;
    if (nextPage <= Math.ceil(response?.meta?.total / response.meta.limit)) {
      handlePagination(nextPage, response.meta.limit);
    }
  };

  const prevPage = () => {
    const prevPage = response?.meta.currentPage - 1;
    if (prevPage > 0) {
      handlePagination(prevPage, response.meta.limit);
    }
  };

  const sendProviderMessage = (message: string, providerIds: string[]) => {
    try {
      launchAction(
        {
          method: 'PATCH',
          body: JSON.stringify({
            userIds: providerIds,
            messageText: message,
            type: 'NOTIFICATION',
          }),
        },
        undefined,
        `admin-panel/send/email-notifications`,
      );
    } catch (err) {
      console.log(err);
    }
  };
  const handleBanProvider = async (id: string[]) => {
    await launchAction(
      { method: 'PATCH', body: JSON.stringify({ providerIds: id }) },
      undefined,
      'admin-panel/banned/providers',
    );
    getProviders();
  };

  const handleMailProvider = async (
    email: string,
    firstName: string,
    lastName: string,
  ) => {
    await launchAction(
      { method: 'POST', body: JSON.stringify({ email, firstName, lastName }) },
      undefined,
      `admin/profile/provider/send-incomplete-email`,
    );
    getProviders();
  };

  const handleDeleteProvider = (providerIds: string[]) => {
    launchAction({ method: 'DELETE', body: JSON.stringify({ providerIds }) }, undefined, `admin-panel/delete/providers`);
  };

  const handleBaneMultipleProvider = (ids: string[]) => {
    launchAction(
      { method: 'PATCH', body: JSON.stringify({ providerIds: ids }) },
      undefined,
      'admin-panel/banned/providers',
    );
  };
  const handleDeleteMultipleProvider = (ids: string[]) => {
    launchAction(
      { method: 'DELETE', body: JSON.stringify({ providerIds: ids }) },
      undefined,
      'admin/delete-providers',
    );
  };

  return {
    getProviders,
    nextPage,
    prevPage,
    banProvider: handleBanProvider,
    mailProvider: handleMailProvider,
    deleteProvider: handleDeleteProvider,
    banMultipleProvider: handleBaneMultipleProvider,
    deleteMultipleProviders: handleDeleteMultipleProvider,
    applyFilters,
    sendProviderMessage,
    providers: response?.data,
    totalPages: Math.ceil(response?.meta?.total / response?.meta?.limit) || 0,
    currentPage: response?.meta?.currentPage || 0,
    isLoading,
    error: errorMessage,
  };
};

export default useProviders;
