import { useEffect, useState, useRef } from 'react';
import useApi from '../../../hooks/useApi';
import { BookingResponse } from '../../../models/bookings';

const useBookings = (params?: Record<string, any>) => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall } =
    useApi<any>('admin-panel/all/bookings');
  const { launchCall: launchAction } = useApi('admin/cancel-booking');

  useEffect(() => {
    // Let's make automatically since by the moment
    // there is no other reason to not do it.
    getBookings(params);
  }, []);

  const getBookings = (params?: Record<string, string | number>, data?: Record<string, any>) => {
    try {
      applyFiltersRef.current = { ...applyFiltersRef.current, ...data };
      launchCall({
        method: 'POST',
        body: JSON.stringify(applyFiltersRef.current),
      },
      params,);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelBooking = (bookingId: string[], message: string) => {
    try {
      launchAction({
        method: 'POST',
        body: JSON.stringify({
          bookingIds: bookingId,
          cancelReason: message,
          status: 'cancel',
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilters = (sorting: { status?: string[], order?: string }, reset?: boolean) => {
    if (reset) {
      applyFiltersRef.current = { sortBy: 'createdAt', order: 'desc' };
    }
    getBookings(params, { ...sorting, skip: 0 });
  };

  const handlePagination = (page: number, offset: number) => {
    const identifier = page - 1;
    getBookings({ ...params, page: page, limit: offset });
  };

  const nextPage = () => {
    const nextPage = response?.meta.currentPage + 1;
    if (nextPage <= Math.ceil(response?.meta?.total / response.meta.limit)) {
      handlePagination(nextPage, response.meta.limit);
    }
  };

  const prevPage = () => {
    const prevPage = response?.meta.currentPage - 1;
    if (prevPage > 0) {
      handlePagination(prevPage, response.meta.limit);
    }
  };

  return {
    getBookings,
    nextPage,
    prevPage,
    applyFilters,
    cancelBooking,
    bookings: response?.data,
    totalPages: Math.ceil(response?.meta?.total / response?.meta?.limit) || 0,
    currentPage: response?.meta?.currentPage || 0,
    isLoading,
    error: errorMessage,
  };
};

export default useBookings;
