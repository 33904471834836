import useApi from '../../hooks/useApi';
import { ForgotPasswordResponse } from '../../models/forgot-password';

const useForgotPassword = () => {
  const { launchCall, response, isLoading, error } =
    useApi<ForgotPasswordResponse>('users/v2/admin-forgot-password');

  const forgotPassword = (email: string) => {
    launchCall({
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });
  };

  return { response, forgotPassword, isLoading, error };
};

export default useForgotPassword;
