import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import { BestListingTable, Listing, ListingTable } from '../models/listings';

const ListingColumns: TableColumn<ListingTable>[] = [
  { id: 'id', label: 'ID' },
  { id: 'title', label: 'Title' },
  { id: 'provider', label: 'Provider' },
  { id: 'type', label: 'Type' },
  { id: 'rating', label: 'Rating' },
  { id: 'status', label: 'Status' },
  { id: 'review', label: 'Review' },
  { id: 'createdAt', label: 'Date' },
];

const getTableHeader = (readOnly: boolean) => {
  return readOnly
    ? ListingColumns
    : ([
        ...ListingColumns,
        { id: 'actions', label: 'Actions' },
      ] as TableColumn<ListingTable>[]);
};

export const ListingToListingDataTable = (
  listings: Listing[],
  readOnly = false,
) => {
  const data: ListingTable[] = listings.map(
    (
      {
        id,
        uuid,
        type,
        status,
        avgRating,
        title,
        provider,
        totalReviewers,
        isApproved,
        createdAt,
      },
      index,
    ) => {
      // (item.isApproved && item.status !== ‘UNDERREVIEW’) ||
      //       [‘DRAFT’, ‘COMPLETED’].includes(item.status)
      //         ? firstLetterCapital(item.status)
      //         : item.status === ‘UNDERREVIEW’
      //         ? string.underReview
      //         : string.pendingReview
      const values: ListingTable = {
        id: id,
        uuid: uuid,
        serial: (index + 1).toString(),
        rating: avgRating,
        type,
        provider: provider?.user?.fullName,
        status:
          (isApproved && status !== 'UNDERREVIEW') ||
          ['DRAFT', 'COMPLETED'].includes(status)
            ? status
            : status === 'UNDERREVIEW'
            ? 'Under Review'
            : 'Pending Review',
        isApproved,
        review: totalReviewers,
        title,
        createdAt: format(
          createdAt ? new Date(createdAt) : new Date(),
          'MM-dd-yyy hh:mm a',
        ),
      };
      if (!readOnly) {
        values.actions = 'ACTIONS';
      }
      return values;
    },
  );

  return {
    headers: getTableHeader(readOnly),
    data,
  };
};

const BestListingColumns: TableColumn<BestListingTable>[] = [
  { id: 'id', label: 'ID' },
  { id: 'title', label: 'Title' },
  { id: 'type', label: 'Type' },
  { id: 'status', label: 'Status' },
];

const getBestTableHeader = () => {
  return BestListingColumns;
};

export const BestListingToListingDataTable = (listings: Listing[]) => {
  const data: BestListingTable[] = listings.map(
    (
      { id, type, status, title, isApproved }
    ) => {
      const values: BestListingTable = {
        id,
        type,
        status:
          (isApproved && status !== 'UNDERREVIEW') ||
          ['DRAFT', 'COMPLETED'].includes(status)
            ? status
            : status === 'UNDERREVIEW'
            ? 'Under Review'
            : 'Pending Review',
        title,
      };
      return values;
    },
  );

  return {
    headers: getBestTableHeader(),
    data,
  };
};
