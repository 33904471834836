import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import { AdminDownloded, Admins, AdminTable } from '../models/admin';

const AdminColumn: TableColumn<AdminTable>[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'role', label: 'Role' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];

export const AdminToAdminTable = (admins: Admins[]) => {
  const data: AdminTable[] = admins.map(
    (
      {
        id,
        userId,
        email,
        firstName,
        lastName,
        role,
        createdAt,
        deleted,
        isActive,
      },
      index,
    ) => ({
      id: id ?? 'N/A',
      userId: userId,
      serial: (index + 1).toString(),
      email: email,
      role: role ?? 'N/A',
      firstName: firstName ?? 'N/A',
      lastName: lastName ?? 'N/A',
      name: firstName + ' ' + lastName,
      status: deleted ? 'Deleted' : isActive ? 'Active' : 'Inactive',
      createdAt: format(
        createdAt ? new Date(createdAt) : new Date(),
        'MM-dd-yyy hh:mm a',
      ),
      actions: 'ACTIONS',
    }),
  );
  return { headers: AdminColumn, data };
};

export const AdminDownloadedData = (admins: Admins[]) => {
  const data: AdminDownloded[] = admins.map(
    (
      { adminId, userId, email, firstName, lastName, role, createdAt, deleted },
      index,
    ) => ({
      serial: (index + 1).toString(),
      id: adminId ?? 'N/A',
      email: email,
      role: role ?? 'N/A',
      name: firstName + ' ' + lastName,
      status: deleted ? 'Inactive' : 'Active',
      createdAt: format(
        createdAt ? new Date(createdAt) : new Date(),
        'MM-dd-yyy hh:mm a',
      ),
    }),
  );
  return { data };
};
