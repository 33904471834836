import React from 'react'
import Button from '../../components/ui/Button';
import useApi from '../../hooks/useApi';

const ToggleUserWall = ({metrics, updateMetrics}: any) => {
    const {isLoading, launchCall } =
    useApi<any>('admin-panel/change-view-listing-status');
    
    const toggleUserWall = () => {
        try {
            launchCall({ method: 'GET' }, {status: !metrics?.isUserViewListingByPRVID});
            updateMetrics();
        } catch (err) {
            console.log('err', err)
        }
    }

  return !metrics || metrics?.isUserViewListingByPRVID === undefined ? null : (
    <Button
          text={`Wall - ${metrics?.isUserViewListingByPRVID ? 'ON' : 'OFF'}`}
          className={`${metrics?.isUserViewListingByPRVID ? '!bg-green-700' : 'bg-slate-500'}`}
          onClick={toggleUserWall}
          showLoader={isLoading}
          type="button"
          />
  )
}

export default ToggleUserWall;