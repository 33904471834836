/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import useChangePassword from './useChangePassword';
import { toast } from 'react-toastify';

const ChangePassword = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const newPasswordRef = useRef('');
  const confirmPasswordRef = useRef('');
  const emailRef = useRef(queryParams.get('email') ?? '');
  const tokenRef = useRef(queryParams.get('token') ?? '');
  const { changePassword, response, isLoading, error, status } =
    useChangePassword();
  const [passwordNotMatchedRef, setPasswordNotMatchedRef] = useState(false);

  useEffect(() => {
    if (response) {
      toast.success(response?.message);
      navigate('/login');
    }
  }, [response]);

  const onSubmitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (newPasswordRef?.current !== confirmPasswordRef?.current) {
      setPasswordNotMatchedRef(true);
    } else {
      changePassword(
        newPasswordRef.current,
        emailRef?.current,
        tokenRef?.current,
      );
      (response?.status === 200 || response?.status === 201) &&
        toast.success(response?.message);
    }
  };

  const onPasswordChange = (value: string) => {
    newPasswordRef.current = value;
    if (newPasswordRef?.current === confirmPasswordRef?.current) {
      setPasswordNotMatchedRef(false);
    }
  };
  const onConfirmPasswordChange = (value: string) => {
    confirmPasswordRef.current = value;
    if (newPasswordRef?.current === confirmPasswordRef?.current) {
      setPasswordNotMatchedRef(false);
    }
  };

  return (
    <div className="flex w-full h-full">
      <div className="hidden w-[50%] h-[100vh] bg-[url('./assets/login-bg.jpeg')] bg-cover bg-center min-[920px]:block" />
      <div className="w-full h-full flex flex-col justify-center items-center min-[920px]:w-[50%]">
        <div className="block absolute w-full h-full bg-[url('./assets/login-without-bg.png')] bg-cover bg-center -z-10 blur-[2px] min-[920px]:hidden" />
        <form
          className="w-[360px] h-56 flex flex-col justify-between"
          onSubmit={onSubmitHandle}>
          <h1 className="text-5xl text-white min-[920px]:text-black">
            Reset Password
          </h1>
          <Input
            required
            className="max-[920px]:border-transparent"
            type="password"
            placeholder="New Password"
            onInput={onPasswordChange}
          />
          <Input
            required
            className="max-[920px]:border-transparent"
            type="password"
            placeholder="Confirm Password"
            onInput={onConfirmPasswordChange}
          />
          <Button type="submit" text="Submit" showLoader={isLoading} />
          <span
            className={`text-xs text-center text-red-500 font-bold py-2 ${
              !error && 'opacity-0'
            }`}>
            The new password is same as your previous password please choose
            another password or the token might be invalid.
          </span>
          <span
            className={`text-xs text-center text-red-500 font-bold py-2 ${
              !passwordNotMatchedRef && 'opacity-0'
            }`}>
            Password does not match !
          </span>
          <span
            className={`text-sm text-center text-primary-main font-bold py-2 ${
              !response && 'opacity-0'
            }`}>
            {response?.message}
          </span>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
