import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as ViewIcon } from '../../assets/eye-fill.svg';
import { ReactComponent as BanIcon } from '../../assets/ban.svg';
import { ReactComponent as MessageIcon } from '../../assets/message.svg';
import { ReactComponent as OtpIcon } from '../../assets/otp-icon.svg';
import { ReactComponent as MailIcon } from '../../assets/mail.svg';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import StatusHandler from '../../components/StatusHandler';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import Table, { TableColumn } from '../../components/ui/Table';
import { ProviderTable } from '../../models/provider';
import { ProvidersToProviderDataTable } from '../../utils/providers';
import useProviders from './useProviders';
import Alert from '../../components/Alert';
import { NotificationDialog } from '../../components/NotificationDialog';
import Modal from '../../components/Modal';
import { toast } from 'react-toastify';
import NewTable from '../../components/ui/NewTable';
import BreadCrumbs from '../../components/BreadCrumbs';

const DateOrder = [
  {
    id: 'desc',
    value: 'Newest first',
  },
  {
    id: 'asc',
    value: 'Oldest first',
  },
];

const Status = [
  {
    id: 'complete',
    value: 'Completed',
  },
  {
    id: 'incomplete',
    value: 'In-completed',
  },
  {
    id: 'banned',
    value: 'Banned',
  },
];

type Props = {
  providerRole?: string;
};

const Providers = ({ providerRole = 'provider' }: Props) => {
  const currentAction = useRef<string>();
  const activeProvider = useRef<ProviderTable>();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [filters, setSelectedFilters] = useState<{
    sortOrder?: DropdownOption;
    status?: DropdownOption;
    search?: string;
  }>({ sortOrder: DateOrder[0] });
  const [alertDescription, setAlertDescription] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [selectedProviders, setSelectedProviders] = useState<ProviderTable[]>(
    [],
  );
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [providersData, setProvidersData] = useState<{
    headers: TableColumn<ProviderTable>[];
    data: ProviderTable[];
  }>({ headers: [], data: [] });
  const {
    getProviders,
    providers,
    isLoading,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    mailProvider,
    banProvider,
    applyFilters,
    banMultipleProvider,
    deleteMultipleProviders,
    deleteProvider,
    sendProviderMessage,
    error,
  } = useProviders({ limit: 20 });
  const navigate = useNavigate();

  useEffect(() => {
    if (providers) {
      setProvidersData(
        ProvidersToProviderDataTable(providers, false, providerRole),
      );
    }
  }, [providers]);

  const onCloseAlert = () => {
    setDisplayAlert(false);
    activeProvider.current = undefined;
    currentAction.current = undefined;
  };

  const onCloseModal = () => {
    setDisplayModal(false);
    activeProvider.current = undefined;
    currentAction.current = undefined;
  };

  const handleAlertAction = () => {
    if (!currentAction.current) {
      return;
    }

    switch (currentAction.current) {
      case 'delete':
        handleDeleteProvider();
        return;
      case 'ban':
        handleBanProvider();
        return;
      case 'mail':
        handleMailProvider();
        return;
      case 'multi-ban':
        handleMultiBanProvider();
        return;
      case 'multi-delete':
        handleMultiDeleteProvider();
        return;
      default:
        return;
    }
  };

  const handleDeleteProvider = () => {
    const currentProvider = activeProvider.current;
    if (!currentProvider) {
      return;
    }
    deleteProvider([currentProvider.id]);
    const data = providersData.data.reduce((all, provider) => {
      if (provider.id !== currentProvider.id) {
        all.push(provider);
      }
      return all;
    }, [] as ProviderTable[]);

    setProvidersData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const handleMultiDeleteProvider = () => {
    deleteMultipleProviders(selectedProviders.map(provider => provider.id));
    const data = providersData.data.reduce((all, provider) => {
      if (!selectedProviders.find(({ id }) => id === provider.id)) {
        all.push(provider);
      }
      return all;
    }, [] as ProviderTable[]);
    setSelectedProviders([]);
    setProvidersData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const handleMultiBanProvider = () => {
    const providerWithActiveBooking = selectedProviders.filter(
      provider => provider?.hasActiveBooking === true,
    );
    if (providerWithActiveBooking?.length > 0) {
      navigate('/bookings');
    } else {
      banMultipleProvider(selectedProviders.map(provider => provider.id));
      const data = providersData.data.reduce((all, provider) => {
        if (!selectedProviders.find(({ id }) => id === provider.id)) {
          all.push(provider);
        }
        return all;
      }, [] as ProviderTable[]);
      setSelectedProviders([]);
      setProvidersData(prev => ({ ...prev, data }));
      setDisplayAlert(false);
    }
  };

  const handleBanProvider = () => {
    const currentProvider = activeProvider.current;
    if (!currentProvider) {
      return;
    }
    if (
      currentProvider?.hasActiveBooking ||
      currentProvider?.hasUpcomingBooking
    ) {
      navigate(`/bookings`);
    } else {
      banProvider([currentProvider.id]);

      const data = providersData.data.reduce((all, provider) => {
        if (provider.id !== currentProvider.id) {
          all.push(provider);
        }
        return all;
      }, [] as ProviderTable[]);

      setProvidersData(prev => ({ ...prev, data }));
      getProviders();
      setDisplayAlert(false);
    }
  };

  const handleMailProvider = () => {
    const currentProvider = activeProvider.current;
    if (!currentProvider) {
      return;
    }
    const { email, firstName, lastName } = currentProvider;
    mailProvider(email, firstName, lastName);
    toast.success('Email has been sent to the provider');
    getProviders();
    setDisplayAlert(false);
  };

  const handleSortByDate = (option: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, sortOrder: option }));
    applyFilters({ sortOrder: option.id });
  };

  const handleSortByStatus = (option: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, status: option }));
    applyFilters({ status: option.id });
  };

  const handleDeleteMultipleAlertProvider = () => {
    setAlertDescription(
      `Are you sure you want to delete these ${providerRole}s?`,
    );
    setDisplayAlert(true);
    currentAction.current = 'multi-delete';
  };

  const handleBanMultipleAlertProvider = () => {
    const providerWithActiveBooking = selectedProviders.filter(
      provider =>
        provider?.hasActiveBooking === true ||
        provider?.hasUpcomingBooking === true,
    );
    setAlertDescription(
      `${
        providerWithActiveBooking?.length > 0
          ? 'These provider has active booking. Please cancel those first. Do you want to proceed to booking page?'
          : `Are you sure you want to ban these ${providerRole}?`
      }`,
    );
    setDisplayAlert(true);
    currentAction.current = 'multi-ban';
  };

  const handleDeleteAlertProvider = (row: ProviderTable) => {
    setAlertDescription(
      `Are you sure you want to delete this ${providerRole}?`,
    );
    setDisplayAlert(true);
    activeProvider.current = row;
    currentAction.current = 'delete';
  };
  const handleBanAlertProvider = (row: ProviderTable) => {
    if (row?.status === 'Banned') {
      return;
    }
    setAlertDescription(
      `${
        row?.hasActiveBooking || row?.hasUpcomingBooking
          ? 'This provider has active booking. Please cancel those first. Do you want to proceed to booking page?'
          : `Are you sure you want to ban this ${providerRole}?`
      }`,
    );
    setDisplayAlert(true);
    activeProvider.current = row;
    currentAction.current = 'ban';
  };

  const handleMailAlertProvider = (row: ProviderTable) => {
    if (row?.status !== 'Incompleted') {
      return;
    }
    setAlertDescription('Are you sure you want to send the Mail?');
    setDisplayAlert(true);
    activeProvider.current = row;
    currentAction.current = 'mail';
  };

  const handleViewOtp = (row: ProviderTable) => {
    setModalDescription(
      `Sign up Otp: ${row?.signUpOtp ?? 'N/A'}. Phone Update Otp: ${
        row?.phoneUpdateOtp ?? row?.signUpOtp ?? 'N/A' // This needs to removed after BE solve the update phone otp issue
      }. Two Factor Otp: ${row?.twoFaOtp ?? 'N/A'}`,
    );
    setDisplayModal(true);
    activeProvider.current = row;
    currentAction.current = 'otp';
  };

  const handleMessage = (row: ProviderTable) => {
    activeProvider.current = row;
    handleToggleDialog();
  };

  const renderActions = (row: ProviderTable) => (
    <>
      <MailIcon
        title="Email Provider"
        className={`mr-2 ${
          row?.status !== 'Incompleted'
            ? 'disabled fill-[#939090] cursor-not-allowed'
            : 'block fill-primary-main cursor-pointer'
        }`}
        onClick={() => handleMailAlertProvider(row)}
      />
      {/* <DeleteIcon
        title="Delete Provider"
        className="mr-2 fill-[#d63737] cursor-pointer"
        onClick={() => handleDeleteAlertProvider(row)}
      /> */}
      <BanIcon
        title="Ban Provider"
        className={`mr-2 ${
          row?.status === 'Banned'
            ? 'disabled fill-[#939090] cursor-not-allowed'
            : 'block fill-[#d63737] cursor-pointer'
        }`}
        onClick={() => handleBanAlertProvider(row)}
      />
      <MessageIcon
        title="Message Provider"
        className="mr-2 fill-[#37a4d6] cursor-pointer"
        onClick={() => handleMessage(row)}
      />
      {/* <PencilIcon
        title="Edit Provider"
        className="fill-[#ffc904] cursor-pointer"
        onClick={() => onEditListing(row.id)}
      /> */}
      <ViewIcon
        title="View Provider"
        className="fill-[#ffc904] cursor-pointer"
        onClick={() => handleViewProvider(row.id)}
      />
      <OtpIcon
        title="View Otp"
        className="fill-primary-main cursor-pointer w-5 pl-1"
        onClick={() => handleViewOtp(row)}
      />
    </>
  );

  const handleSearchChange = (value: string) => {
    setSelectedFilters(prev => ({ ...prev, search: value }));
    applyFilters({ searchString: value });
  };

  const handleRowSelection = (selected: boolean, row: ProviderTable) => {
    if (selected) {
      setSelectedProviders(prev => [...prev, row]);
      return;
    }

    setSelectedProviders(providers =>
      providers.filter(provider => provider.id !== row.id),
    );
  };

  const onEditListing = (rowId: string) => {
    if (!providers) {
      return;
    }

    const providerData = providers.find(
      (dataItem: any) => dataItem._id === rowId,
    );
    navigate(`edit/${rowId}`, {
      state: { data: providerData, role: providerRole },
    });
  };

  const handleViewProvider = (rowId: string) => {
    if (!providers) {
      return;
    }
    const providerData = providers.find(
      (dataItem: any) => dataItem.id === rowId,
    );
    navigate(`${rowId}`, {
      state: providerData,
    });
  };

  const handleToggleDialog = () => {
    setToggleDialog(prev => !prev);
  };

  const handleSendNotification = (message: string) => {
    handleToggleDialog();
    const providers = !!activeProvider.current
      ? [activeProvider.current]
      : selectedProviders;
    activeProvider.current = undefined;
    setSelectedProviders([]);
    sendProviderMessage(
      message,
      providers.map(provider => provider.id),
    );
  };

  const handleResetFilters = () => {
    setSelectedFilters({ sortOrder: DateOrder[0] });
    applyFilters({ sortOrder: DateOrder[0].id }, true);
  };

  const renderSearch = [
    {
      id: 1,
      placeholder: 'Search name or ID',
      value: filters?.search,
      onInput: handleSearchChange,
    },
  ];

  const renderFilters = [
    {
      id: 1,
      label: 'Date',
      defaultValue: filters?.sortOrder,
      options: DateOrder,
      action: handleSortByDate,
    },
    {
      id: 2,
      label: 'Status',
      defaultValue: filters?.status,
      options: Status,
      action: handleSortByStatus,
    },
  ];

  const downloadCSV = {
    fileName: 'Sporti Providers',
    data: providersData.data,
  };

  const breadCrumbs = [
    {
      displayName: 'Providers',
    },
  ];

  return (
    <>
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertDescription}
        />
      )}

      {displayModal && <Modal onClose={onCloseModal} body={modalDescription} />}

      {toggleDialog && (
        <NotificationDialog
          onClose={handleToggleDialog}
          placeholder="Message to provider"
          onSubmit={handleSendNotification}
        />
      )}
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide">
        <h1 className="text-secondary-main text-[50px] my-6 first-letter:uppercase">
          {providerRole}
        </h1>
        <BreadCrumbs breadcrumbs={breadCrumbs} />
        {selectedProviders.length > 1 && (
          <div className="flex justify-end">
            <button
              className="flex items-center p-2 mr-2"
              onClick={handleDeleteMultipleAlertProvider}>
              <DeleteIcon className="fill-[#d63737] mr-1" />
              <span className="text-[#d63737] font-semibold">{`Delete ${providerRole}s`}</span>
            </button>
            <button
              className="flex p-2 items-center"
              onClick={handleBanMultipleAlertProvider}>
              <BanIcon className="fill-[#d63737] mr-1" />
              <span className="text-[#d63737] font-semibold">{`Ban ${providerRole}s`}</span>
            </button>
            <button
              className="flex p-2 items-center"
              onClick={handleToggleDialog}>
              <MessageIcon className="fill-[#37a4d6] mr-1" />
              <span className="text-[#37a4d6] font-semibold">{`Notify ${providerRole}s`}</span>
            </button>
          </div>
        )}
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <NewTable
              selectable
              getActions={renderActions}
              selectedRows={selectedProviders}
              onRowSelection={handleRowSelection}
              data={providersData?.data}
              columns={providersData?.headers}
              currentPage={currentPage}
              filterable
              filters={renderFilters}
              searchable
              search={renderSearch}
              reset={handleResetFilters}
              downloadCSV={downloadCSV}
            />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default Providers;
